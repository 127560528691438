import { Folio, FolioInfo, FolioSection, Tabla, buildData } from "eureka-design"
import { useEffect, useState } from "react"
import { MESES, capitalize, numberFormat } from "../../js/util"
import { AdminComision } from "../../js/AdministradorComision"

export const STATE_DETALLE_MOVIMIENTO = 'stateMovimiento'

const DetalleMovimiento = ({ stateMovimiento }) => {    
    /** ESTADOS */
    const [detallesComisiones, fijaDetallesComisiones] = useState(null)
    const [totalComisiones, fijaTotalComisiones] = useState(0)
    const [total, fijaTotal] = useState(0)

    useEffect(() => {
        function configurarInfoComisiones(comisiones) {
            if(comisiones?.length > 0) {
                let total = 0
                let detalles = (
                    <FolioInfo titulo="Comisionistas">
                        {
                            comisiones.map((comision, indice) => {
                                total += comision.comision
                                return AdminComision.creaComponenteInfo(comision, indice)
                            })
                        }
                    </FolioInfo>
                )
                fijaDetallesComisiones(detalles)
                fijaTotalComisiones(total)
            }
            // else no hay comisione para la venta
        }

        let total = 0
        stateMovimiento?.eureka_st_transacciones_conceptos?.forEach((concepto) => {
            total += parseFloat(concepto?.costo) * parseInt(concepto?.cantidad)
        })
        fijaTotal(total)

        if(stateMovimiento?.eureka_st_comisiones?.length > 0) {
            configurarInfoComisiones(stateMovimiento.eureka_st_comisiones)
        }
        // else no tiene ni comisiones guardadas
    }, [stateMovimiento])

    let fecha = stateMovimiento?.fecha ? new Date(stateMovimiento.fecha) : null
    return (
        <Folio>
            <FolioInfo titulo="Detalles">
                <FolioSection titulo={"Folio"}>
                    <span>{stateMovimiento?.token_stripe}</span>
                </FolioSection>
                <FolioSection titulo={"vendedor"}>
                    <span>{capitalize(stateMovimiento?.eureka_ac_usuario?.nombre ?? 'Sin vendedor')}</span>
                </FolioSection>
                <FolioSection titulo={"Fecha de creacion"}>
                    <span>{fecha ? `${fecha.getDate()} de ${MESES[fecha.getMonth()]} de ${fecha.getFullYear()}` : 'Fecha invalida'}</span>
                </FolioSection>
                <FolioSection titulo={"Importe total"}>
                    <span>{numberFormat(total, 2, '.', ',', '$')}</span>
                </FolioSection>
                {
                    (totalComisiones === 0 && stateMovimiento?.cxp_cxc !== 'pagar') ? 
                        <FolioSection>
                            <span>Para visual la(s) comision(es) el periodo debe haber terminado</span>
                        </FolioSection> : 
                        <FolioSection titulo={"Importe total comisiones"}>
                            <span>{numberFormat(totalComisiones, 2, '.', ',', '$')}</span>
                        </FolioSection>
                }

            </FolioInfo>
            
            {detallesComisiones}
            
            <FolioInfo titulo="Conceptos">
                <Tabla
                    checkbox={false}
                    shadow={false}
                    headers={[
                        { key: "concepto", text: "Concepto", type: "text" },
                        { key: "costo",   text: "Costo",   type: "number" },
                        { key: "precio",   text: "Precio",   type: "number" },
                        { key: "cantidad", text: "Cantidad", type: "number" },
                        { key: "total",    text: "Total",    type: "number" }
                    ]}
                    data={buildData(stateMovimiento?.eureka_st_transacciones_conceptos ?? [], [
                        { 
                            key: "concepto", type: "text", text: (item) => {
                                return item.eureka_st_producto.nombre
                            }
                        },
                        { 
                            key: "costo", type: "number", text: (item) => {
                                return numberFormat(item.eureka_st_producto.costo, 2, '.', ',', '$')
                            }
                        },
                        { 
                            key: "precio",   type: "number", text: (item) => {
                                return numberFormat(item.costo, 2, '.', ',', '$')
                            }
                        },
                        { key: "cantidad", type: "number" },
                        { 
                            key: "total",    type: "number", text: (item) => {
                                let total = parseFloat(item.cantidad) * parseFloat(item.costo)
                                return numberFormat(total, 2, '.', ',', '$')
                            } 
                        }
                    ])}
                />
            </FolioInfo>
        </Folio>
    )
}

export default DetalleMovimiento