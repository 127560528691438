import { Tabla, buildData, Miniatura, ChipEstatus } from "eureka-design"
import { useCrearDistribuidorMutation, useObtenerDistribuidoresQuery } from "../../js/services/distribuidor"
import { useDispatch } from "react-redux"
import nouser from ".././../img/nouser.jpg"
import { setHandler } from "../../js/feactures/HandlerQuerySlice"
import { NOM_FORM_DISTRIBUIDOR } from "./FormDistribuidor"
import { config } from "../../config/config"
import { NOM_DISTRIBUIDOR_ID } from "./Distribuidor"
import { NOM_FORM_MOVIMIENTO } from "../ventas/FormMovimiento"
import { useState } from "react"

export default function Distribuidores({ Push }) {
    const dispatch = useDispatch()
    const [busqueda, fijaBusqueda] = useState({ col: null, val: null })
    const [iniciarBusquedad, fijaIniciarBuscqueda] = useState(false)
    const { data: distribuidores, isLoading, refetch: refrescarDistribuidores } = useObtenerDistribuidoresQuery(busqueda)
    const [actualizarDistribuidor] = useCrearDistribuidorMutation()

    if (isLoading) return
    else {
        return (
            <>
                <Tabla
                    checkbox={false}
                    keys={[
                        {
                            text: "Todos",
                            filtro: () => true
                        },
                        {
                            text: "Activos",
                            filtro: item => item.item.estatus === "activo"
                        },
                        {
                            text: "Archivados",
                            filtro: item => item.item.estatus === "inactivo"
                        },
                    ]}
                    busqueda={{
                        placeholder: "Buscar por nombre...",
                        fn: (val) => {
                            if(iniciarBusquedad) {
                                fijaBusqueda({
                                    col: 'nombre',
                                    val
                                })
                                refrescarDistribuidores()
                            }
                            else {
                                fijaIniciarBuscqueda(true)
                            }
                        }
                    }}
                    rowSize={"medium"}
                    headers={[
                        { text: "", key: "foto", type: "text", size: "small" },
                        { text: "Nombre", key: "nombre", type: "text" },
                        { text: "Teléfono", key: "telefono", type: "text" },
                        { text: "Correo", key: "correo", type: "text" },
                        { text: "Patrocinador", key: "contacto", type: "text" },
                        { text: "", key: "estatus", type: "text" },
                    ]}
                    botonesRow={
                        [
                            {
                                icono: "fa-solid fa-circle-info",
                                tooltip: "Ver información",
                                onClick: (item) => {
                                    dispatch(setHandler({
                                        state: NOM_DISTRIBUIDOR_ID,
                                        content: { id: item.id }
                                    }))
                                    Push('distribuidor') 
                                },
                            },
                            {
                                icono: "fa-hand-holding-dollar",
                                tooltip: "Crear Venta",
                                onClick: (item) => {
                                    let distribuidor = item.item

                                    Push('form_movimiento', {
                                        [NOM_FORM_MOVIMIENTO]: {
                                            id: null,
                                            fecha: null,
                                            total: 0,
                                            cxp_cxc: 'cobrar',
                                            vendedor: distribuidor.id,
                                            nomVendedor: distribuidor.nombre,
                                        }
                                    })
                                }
                            },
                            {
                                icono: "fa-solid fa-file-edit",
                                tooltip: "Editar",
                                onClick: (item) => {
                                    let fotoID = document.getElementById(`foto${item.id}`)?.value
                                    let contactoAbuelo = document.getElementById(`abueloContacto${item.id}`)?.value
                                    let abueloID = document.getElementById(`abuelo${item.id}`)?.value
                                    let contactoPadre = document.getElementById(`padreContacto${item.id}`)?.value
                                    let padreID = document.getElementById(`padre${item.id}`)?.value
                                    let props = {}

                                    item.content.forEach((c) => {
                                        if(c.key === 'foto')
                                            props['preview'] = c.text?.props?.children[0]?.props?.src
                                        else
                                            props[c.key] = c.value
                                    })

                                    dispatch(setHandler({
                                        state: NOM_FORM_DISTRIBUIDOR,
                                        content: { 
                                            id: item.id,
                                            abueloID: abueloID ? parseInt(abueloID) : null,
                                            padreID: padreID ? parseInt(padreID) : null,
                                            contactoAbueloID: contactoAbuelo ? parseInt(contactoAbuelo) : null,
                                            contactoPadreID: contactoPadre ? parseInt(contactoPadre) : null,
                                            foto: fotoID === '' ? null : parseInt(fotoID),
                                            eureka_pa_tarjetas: item?.item?.eureka_pa_tarjetas ?? [],
                                            ...props,
                                        }
                                    }))
                                    Push('formDistribuidor') 
                                },
                            },
                            {
                                icono: "fa-solid fa-trash-can",
                                tooltip: "Archivar",
                                onClick: (item) => {
                                    actualizarDistribuidor({
                                        id: item.id,
                                        body: { estatus: (item?.content[4]?.value === 'activo') ? 'inactivo' : 'activo' }
                                    })
                                    .unwrap()
                                    .then((res) => {
                                        if(res.estatus === 'OK') refrescarDistribuidores()
                                    })
                                },
                            },
                        ]
                    }
                    botonesHead={[
                        {
                            icono: "fa-solid fa-plus",
                            tooltip: "Agregar",
                            onClick: () => {
                                dispatch(setHandler({
                                    state: NOM_FORM_DISTRIBUIDOR,
                                    content: {
                                        id: null,
                                        padreID: null,
                                        abueloID: null,
                                        contactoPadreID: null,
                                        contactoAbueloID: null,
                                        foto: null,
                                        eureka_pa_tarjetas: [],
                                    }
                                }))
                                Push('formDistribuidor')
                            }
                        },
                    ]}
                    data={
                        buildData(distribuidores, [
                            {
                                key: "foto", type: "text", size: "small", text: (item) => {
                                    let contactos = item.eureka_bs_contactos
                                    let multimedia = item.eureka_cr_multimedia[0]
                                    let foto = null
                                    if (multimedia)
                                        foto = `${config.crufdekFileURL}/${multimedia.carpeta}/${multimedia.nombre}`
                                    else foto = nouser

                                    
                                    return (
                                        <>
                                            <Miniatura
                                                avatar={true}
                                                size="medium"
                                                src={foto}
                                            ></Miniatura>
                                            {
                                                contactos.map((contacto, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <input id={`${contacto.relacion}${item.id}`} type="hidden" value={contacto?.contacto ?? ''} />
                                                            <input id={`${contacto.relacion}Contacto${item.id}`} type="hidden" value={contacto?.id ?? ''} />
                                                        </div>
                                                    )
                                                })
                                            }
                                            <input id={`foto${item.id}`} type="hidden" value={multimedia?.id ?? ''} />
                                        </>
                                    )

                                }
                            },
                            {
                                key: "nombre", type: "text", text: (item) => {
                                    return (
                                        <p style={{ width: 150, margin: 0, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                            {item.nombre.toLowerCase()}
                                        </p>
                                    )
                                }
                            },
                            { key: "telefono", type: "text" },
                            { key: "correo", type: "text" },
                            { 
                                key: "contacto", 
                                type: "text",
                                text: (item) => {
                                    let patrocinadores = item?.eureka_bs_contactos?.filter((contacto) => {
                                        return contacto.relacion === 'padre'
                                    })
                                    
                                    if(patrocinadores.length > 0) return patrocinadores[0]?.eureka_ac_usuario_contacto?.nombre
                                    else return 'Ningúno'
                                }
                            },
                            {
                                key: "estatus", type: "text", text: (item) => {
                                    if (item.estatus === "activo") {
                                        return <ChipEstatus texto={"Activo"} tipo="Aceptado"></ChipEstatus>
                                    }

                                    if (item.estatus === "inactivo") {
                                        return <ChipEstatus texto={"Inactivo"} tipo="Error"></ChipEstatus>
                                    }
                                }
                            },
                        ])
                    }
                />
            </>
        )
    }
}