import { DIA, MIN } from 'account-react-eurekasigma'

export const DOMINIO = window.location.origin
const URL_LOCAL = 'http://localhost:3000'
const URL_DEMO = "https://demo.camvi432.com/"


export const config = {
    colorPrimario: '#1B1881',
    colorSecundario: '#1c3454',
    keyAccount: DOMINIO === URL_LOCAL || DOMINIO === URL_DEMO ? 'dJqHPT3fr8EEN2e2G6uom27dcQoBc00L5uE7HICM' : 'K1uvTJhMEYZHUk2MtPXwlSMihRj1iuUs3K8Lickp',
    keyAPICrufdek: DOMINIO === URL_LOCAL || DOMINIO === URL_DEMO ? "a7Q9exI6VMvG7gcNskuNycZlOoGlKL73vtK2sUZG" : 'uLcL5HGKzUBIaEPJ24MClU36x11Zy9TRnzDUPfsV',
    crufdekURLV1: 'https://crud.eksuite.com/api/v1/CRUFDEK',
    crufdekURL: 'https://crud.eksuite.com/api/v2/CRUFDEK',
    crufdekFileURL: 'https://crud.eksuite.com/api/GetFile',
    dataBaseVersionURL: 'https://crud.eksuite.com/api',
    roles: ['administrador', 'distribuidor'],
    colorPrincipal: '#1B1881',
    CAMVI_ID: 46,
    expCookie: { cantidad: 15, tipo: DIA },
    inactividad: { cantidad: 1, tipo: MIN },
    tiempoRefrescar: { cantidad: 50, tipo: MIN },
    nomCookieUsuario: 'usuario',
    nomCookieToken: 'tokenSesion',
}