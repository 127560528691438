import { Tabla, buildData, Miniatura, ChipEstatus } from "eureka-design"
import { useCrearProductoMutation, useObtenerProductosQuery } from "../../js/services/producto"
import noImage from "../../img/no-logo.png"
import { useDispatch } from "react-redux"
import { setHandler } from "../../js/feactures/HandlerQuerySlice"
import { NOM_FORM_PRODUCTO } from "./FormProducto"
import { config } from "../../config/config"
import { useState } from "react"
import { obtenerRol } from "../../js/util"

export default function Productos({ Push, usuario }) {
    const dispatch = useDispatch()
    const [busqueda, fijaBusqueda] = useState({ col: null, val: null })
    const [iniciarBusquedad, fijaIniciarBuscqueda] = useState(false)
    const { data: productos, isLoading, refetch } = useObtenerProductosQuery(busqueda)
    const [actualizarProducto] = useCrearProductoMutation()
    const [eresAdmin] = useState(obtenerRol(usuario) === 'administrador')

    if(isLoading) return 
    else {
        return (
            <>
                <Tabla
                    checkbox={false}
                    keys={[
                        {
                            text: "Todos",
                            filtro: () => true
                        },
                        {
                            text: "Afiliado",
                            detalle: '',
                            filtro: item => item.item.ecommerce === "offline"
                        },
                        {
                            text: "Compra",
                            detalle: '',
                            filtro: item => item.item.ecommerce === "online"
                        },
                    ]}
                    busqueda={{
                        placeholder: "Buscar por nombre...",
                        fn: (val) => {
                            if(iniciarBusquedad) {
                                fijaBusqueda({
                                    col: 'nombre',
                                    val
                                })
                                refetch()
                            }
                            else {
                                fijaIniciarBuscqueda(true)
                            }
                        }
                    }}
                    rowSize={"medium"}
                    headers={[
                        { text: "", key: "foto", type: "text", size: "small" },
                        { text: "Nombre", key: "nombre", type: "text" },
                        { text: "Costo", key: "costo", type: "precio" },
                        { text: "Precio", key: "precio", type: "precio" },
                        { text: "Existencia", key: "numero", type: "number" },
                        { text: "Categoría", key: "ecommerce", type: "text" },
                        { text: "", key: "estatus", type: "text" }
                    ]}
                    botonesRow={eresAdmin ? [
                        {
                            icono: "fa-solid fa-file-edit",
                            tooltip: "Editar",
                            onClick: (item) => {
                                let fotoID = document.getElementById(`foto${item.id}`)?.value
                                let precioID = document.getElementById(`productoPrecio${item.id}`)?.value
                                let codigo = document.getElementById(`codigo${item.id}`)?.value
                                let descripcion = document.getElementById(`descripcion${item.id}`)?.value
                                let props = {}

                                item.content.forEach((c) => {
                                    if(c.key === 'foto')
                                        props['preview'] = c.text?.props?.children[0]?.props?.src
                                    else if(c.value)
                                        props[c.key] = c.value !== '' ? c.value : null
                                    else
                                        props[c.key] = c.text !== '' ? c.text : null
                                })

                                dispatch(setHandler({
                                    state: NOM_FORM_PRODUCTO,
                                    content: { 
                                        id: item.id,
                                        precioID: precioID ? parseInt(precioID) : null,
                                        fotoID: fotoID === '' ? null : parseInt(fotoID),
                                        codigo: codigo !== '' ? codigo : null,
                                        descripcion: descripcion !== '' ? descripcion : null,
                                        ...props,
                                    }
                                }))
                                Push('formProducto') 
                            },
                        },
                        {
                            icono: "fa-solid fa-trash-can",
                            tooltip: "Archivar",
                            onClick: (item) => {
                                console.log(item)
                                actualizarProducto({
                                    id: item.id,
                                    body: { estatus: item?.content[4]?.value === 'activo' ? 'archivado' : 'activo' }
                                })
                                .unwrap()
                                .then((res) => {
                                    if(res.estatus === 'OK') refetch()
                                })
                            },
                        },
                    ] : []}
                    botonesHead={eresAdmin ? [
                        {
                            icono: "fa-solid fa-plus",
                            tooltip: "Agregar",
                            onClick: (item) => { 
                                dispatch(setHandler({
                                    state: NOM_FORM_PRODUCTO,
                                    content: {
                                        id: null,
                                        precioID: null,
                                        fotoID: null,
                                    }
                                }))
                                Push('formProducto')
                            }
                        },
                    ] : []}
                    data={buildData(productos, [
                        {
                            key: "foto", type: "text", size: "small", text: (item) => {
                                let multimedia = item?.eureka_st_productos_fotos[0]?.eureka_cr_multimedia
                                let productoPrecio = item?.eureka_st_productos_precios
                                let foto = null
                                if (multimedia)
                                    foto = `${config.crufdekFileURL}/${multimedia.carpeta}/${multimedia.nombre}`
                                else foto = noImage

                                return (
                                    <>
                                        <Miniatura
                                            size="medium"
                                            src={foto}
                                            avatar={true}
                                        ></Miniatura>
                                        <input id={`productoPrecio${item.id}`} type="hidden" value={productoPrecio[0]?.id} />
                                        <input id={`foto${item.id}`} type="hidden" value={multimedia ? multimedia.id : ''} />
                                        <input id={`codigo${item.id}`} type="hidden" value={item?.codigo ? item.codigo : ''} />
                                        <input id={`descripcion${item.id}`} type="hidden" value={item?.descripcion ? item.descripcion : ''} />
                                    </>
                                )

                            }
                        },
                        { key: "nombre", type: "text" },
                        { key: "costo", type: "precio" },
                        { 
                            key: "precio", type: "precio", value: (item) => {
                                let precio = item?.eureka_st_productos_precios[0]?.precio
                                return precio ? precio : 0
                            }
                        },
                        { 
                            key: "numero", type: "number", value: (item) => {
                                let cantidad = item?.eureka_st_productos_precios[0]?.numero
                                return cantidad ? cantidad : ''
                            }
                        },
                        { 
                            key: "ecommerce", 
                            type: "text", 
                            text: (item) => {
                                return item.ecommerce === 'offline' ? 'Afiliado' : 'Compra'
                            }
                        },
                        {
                            key: "estatus", type: "text", text: (item) => {
                                if (item.estatus === "activo") {
                                    return <ChipEstatus texto={"Activo"} tipo="Aceptado"></ChipEstatus>
                                }
                                else if (item.estatus === "archivado") {
                                    return <ChipEstatus texto={"Archivado"} tipo="Error"></ChipEstatus>
                                }
                            }
                        }
                    ])}
                />
            </>
        )
    }
}