import { createBrowserRouter } from "react-router-dom";
import Error from "../pages/errors/Error";
import Inicio from "../pages/Inicio";
import IniciarSesion from "../pages/IniciarSesion";
import ProcesarCredenciales from "../pages/ProcesarCredenciales";
import TerminarSesion from "../pages/TerminarSesion";

export const router = createBrowserRouter([
    {
        path: '/',
        element: <Inicio />,
        errorElement: <Error />
    },
    {
        path: '/iniciar_sesion',
        element: <IniciarSesion />,
        errorElement: <Error />
    },
    {
        path: '/procesar_credenciales',
        element: <ProcesarCredenciales />,
        errorElement: <Error />
    },
    {
        path: '/cerrar_sesion',
        element: <TerminarSesion />,
        errorElement: <Error />
    }
])