import { useEffect, useState } from 'react'
import { DOMINIO, config } from '../config/config'
import { CargandoPagina } from 'eureka-design'
import {
    crearCookie,
    configurarVerificacionSesion,
    iniciarProcesoRefrescarToken,
    token,
    // crearSesionLocal,
    // usuario as infoUsuario,
 } from 'account-react-eurekasigma'
import { useSelector } from 'react-redux'
import { useCrearRolUsuarioMutation } from '../js/services/rol'
import Index from './layout/Index'

const ROL_DISTRIBUIDOR = 1

const Inicio = () => {
    /** Sesión entrante */
    const sesionSlice = useSelector(state => state.sesionSlice)

    /** Estados Locales */
    const [verificandoSesion, cambiarEstadoVerificarSesion] = useState(true)
    const [mensajePantallaCarga, fijaMensajePantallaCarga] = useState('Procesando credenciales, por favor espere...')

    /** Hooks */
    const [crearRol] = useCrearRolUsuarioMutation()
    
    useEffect(() => {
        const crearRolUsuario = async (usuario = {}, token = '') => {
            return await crearRol({ 
                token: token, 
                id: null, 
                body: { rol: ROL_DISTRIBUIDOR, usuario: usuario.id }
            })
            .unwrap()
            .then((response) => {
                if(response?.mensaje?.id) {
                    return { 
                        ...response.mensaje,
                        eureka_cr_rol: { nombre: 'distribuidor' }
                    }
                }
                else return null
            })

        }

        const verificarRol = async (usuario = {}, tokenSesion = '') => {
            if(usuario?.eureka_cr_rol_usuarios?.length === 0) {
                let roles = []
                let rol = await crearRolUsuario(usuario, tokenSesion)

                roles.push(rol)
                usuario = {
                    ...usuario,
                    eureka_cr_rol_usuarios: roles
                }
            }
            // else no es la primera sesión

            guardarDatosSesion(usuario, tokenSesion)
            cambiarEstadoVerificarSesion(false)
            
            iniciarProcesoRefrescarToken(
                usuario, 
                tokenSesion,
                config.tiempoRefrescar,
                guardarDatosSesion,
                false,
                config.inactividad,
                `${DOMINIO}/cerrar_sesion`
            )
        }

        /**
         * 
         * @param {object} usuario 
         * @param {string} tokenSesion 
         */
        const guardarDatosSesion = (usuario = {}, tokenSesion = '') => {
            crearCookie(config.nomCookieUsuario, JSON.stringify(usuario), true, config.expCookie)
            crearCookie(config.nomCookieToken, tokenSesion, true, config.expCookie)
            // crearSesionLocal(usuario.id, usuario.nombre, usuario.correo)
            console.log(token())
        }

        if(verificandoSesion) {
            configurarVerificacionSesion(
                sesionSlice,
                config.nomCookieUsuario,
                config.nomCookieToken,
                `${DOMINIO}/cerrar_sesion`,
                verificarRol
            )
        }
        else {
            fijaMensajePantallaCarga('Redirigiendo, por favor espere...')
        }
    }, [verificandoSesion])

    if(verificandoSesion) 
        return <CargandoPagina 
                    mensaje={mensajePantallaCarga} 
                    colorPrincipal={config.colorPrincipal} 
                />
    else
        return <Index />
}

export default Inicio