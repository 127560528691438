import { Tabla, buildData } from "eureka-design"
import { NOM_FORM_REPORTE } from "./DetalleComisiones"
import { obtenerRol } from "../../js/util"
import { useObtenerPeriodoReporteQuery } from "../../js/services/movimiento"

const REGISTROS_POR_PAGINA = 20

export default function Comisiones({ Push, usuario }) {
    const rol = obtenerRol(usuario)
    const esAdministrador = rol === 'administrador'

    const { data: reportes, isLoading: cargandoReportes } = useObtenerPeriodoReporteQuery({ vendedor: esAdministrador ? null : usuario.id })
    
    if(cargandoReportes) return
    else {
        return (
            <>
                <Tabla
                    checkbox={false}
                    paginado={{
                        paginas: Math.round(reportes.length / REGISTROS_POR_PAGINA),
                        paginaCurrent: 1,
                        registrosCurrent: reportes.length < REGISTROS_POR_PAGINA ? reportes.length : REGISTROS_POR_PAGINA,
                        registros: reportes.length,
                        onNext: () => {},
                        onPrev: () => {},
                    }}
                    keys={[
                        { text: "Todos", filtro: () => true }
                    ]}
                    rowSize={"medium"}
                    headers={[
                        { text: "Fecha inicio", key: "fecha_inicio", type: "date" },
                        { text: "Fecha fin", key: "fecha_final", type: "date" },
                        { text: "Tipo", key: "tipo", type: "text" },
                    ]}
                    data={buildData(reportes, [
                        { key: "fecha_inicio", type: "date" },
                        { key: "fecha_final", type: "date" },
                        { key: "tipo", type: "text" },
                    ])}
                    onClickRow={(item) => {
                        let reporte = item.item
                        Push('detalle_comisiones', {
                            [NOM_FORM_REPORTE]: {
                                ...reporte
                            }
                        })
                    }}
                />
            </>
        )
    }
}