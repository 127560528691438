import "../../css/venta.css"
import { numberFormat } from "../../js/util";

const DECIMALES = 2

const ResumeVenta = ({ total = 0, comisiones = [], productos = [] }) => {
    let totalComisiones = 0;
    let infoComisiones = []
    let infoTotalComisionable = null

    if(productos.length > 0) { 
        let totalComisionable = 0.0
        total = 0
    
        productos.forEach((producto) => {
            totalComisionable += producto?.eureka_st_producto?.costo ? (producto.eureka_st_producto.costo * parseFloat(producto.cantidad)) : 0.0
            total += parseFloat(producto.costo) * parseFloat(producto.cantidad)
        })
        infoTotalComisionable = <div className="resumen-venta__info">
                                    Importe Comisionable: <span>{numberFormat(totalComisionable, 2, '.', ',', '$')}</span>
                                </div>
    }
    else {
        total = 0
        productos.forEach((producto) => {
            total += parseFloat(producto.costo) * parseFloat(producto.cantidad)
        }) 
    }

    if(total > 0) {
        for(let i = 0; i < comisiones.length; i++) {
            let comision = parseFloat(comisiones[i].comision)

            infoComisiones.push(
                <div key={i} className="resumen-venta__info">
                    Comisión {comisiones[i].nombre_comision}: <span>-${comision.toFixed(DECIMALES)}</span>
                </div>
            )
            totalComisiones += comision
        }
    }
    // else no puede haber comisiones

    return (
        <div className="resumen-venta">
            { infoTotalComisionable }
            { infoComisiones }
            <div className="resumen-venta__info">
                Importe: <span>${Number(total).toFixed(DECIMALES)}</span>
            </div>
            <div className="resumen-venta__info resumen-venta__info--total">
                TOTAL: <span>${Number(total-totalComisiones).toFixed(DECIMALES)}</span>
            </div>
        </div>
    )
}

export default ResumeVenta