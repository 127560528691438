import { configureStore } from "@reduxjs/toolkit"
import sesionReducer from "../js/feactures/SesionSlice"
import comisionReducer from "../js/feactures/ComisionSlice"
import handlerQuerySlice from "../js/feactures/HandlerQuerySlice"
import { productoApi } from "../js/services/producto"
import { dataBaseApi } from "../js/services/database"
import { movimientoApi } from "../js/services/movimiento" 
import { distribuidorApi } from "../js/services/distribuidor"
import { rolApi } from "../js/services/rol"
import { comisionApi } from "../js/services/comision"
import { tarjetaApi } from "../js/services/tarjeta"
import { facturaApi } from "../js/services/factura"
import { ventaAPI } from "../js/services/venta"
import { reporteAPI } from "../js/services/reporte"

export const store = configureStore({
    reducer: {
        sesionSlice: sesionReducer,
        handlerQuerySlice: handlerQuerySlice,
        comisionSlice: comisionReducer,
        [productoApi.reducerPath]: productoApi.reducer,
        [dataBaseApi.reducerPath]: dataBaseApi.reducer,
        [movimientoApi.reducerPath]: movimientoApi.reducer,
        [distribuidorApi.reducerPath]: distribuidorApi.reducer,
        [rolApi.reducerPath]: rolApi.reducer,
        [comisionApi.reducerPath]: comisionApi.reducer,
        [tarjetaApi.reducerPath]: tarjetaApi.reducer,
        [facturaApi.reducerPath]: facturaApi.reducer,
        [ventaAPI.reducerPath]: ventaAPI.reducer,
        [reporteAPI.reducerPath]: reporteAPI.reducer,
    },
    middleware: (getDefaultMiddleware) => [
        ...getDefaultMiddleware({serializableCheck: false}),
        productoApi.middleware,
        dataBaseApi.middleware,
        movimientoApi.middleware,
        distribuidorApi.middleware,
        rolApi.middleware,
        comisionApi.middleware,
        tarjetaApi.middleware,
        facturaApi.middleware,
        ventaAPI.middleware,
        reporteAPI.middleware,
    ],
})