import { config } from "../config/config"

export const CATALOGO_USOS_FACTURA = 'cfdi_40_usos_cfdi'
export const CATALOGO_IMPUESTOS = 'cfdi_40_impuestos'
export const CATALOGO_MONEDAS = 'cfdi_40_monedas'
export const CATALOGO_REGIMENES_FISCALES = 'cfdi_40_regimenes_fiscales'
export const CATALOGO_TIPOS_COMPROBANTES = 'cfdi_40_tipos_comprobantes'
export const CATALOGO_OBJETOS_IMPUESTO = 'cfdi_40_objetos_impuestos'
export const CATALOGO_CLAVES_UNIDAD = 'cfdi_40_claves_unidades'
export const CATALOGO_TIPOS_FACTORES = 'cfdi_40_tipos_factores'
export const CATALOGO_CLAVES_PRODUCTOS = 'cfdi_40_productos_servicios'
export const CATALOGO_TIPOS_RELACIONES = 'cfdi_40_tipos_relaciones'
export const CATALOGO_FORMAS_PAGOS = 'cfdi_40_formas_pago'
export const CATALOGO_METODOS_PAGOS = 'cfdi_40_metodos_pago'
export const REGLAS_TASA_CUOTA = 'cfdi_40_reglas_tasa_cuota'
export const CATALOGO_MOTIVOS_CANCELACION = 'cfdi_40_motivos_cancelacion'
export const CATALOGO_PAISES = 'paises_iso_3166_1'

export const OBJETO_IIMPUESTO = '02'
export const IEPS = 'IEPS'

/**
 * 
 * @param {string} nomCatalogo 
 * @param {boolean} busquedad
 * @param {string} expresion
 * @returns 
 */
export const obtenerCatalogoSAT = async (nomCatalogo = '', busquedad = false, expresion = '') => {
    let res = null
    if(busquedad) {
        if(expresion.length >= 3)
            res = await fetch(`https://invoice.eksuite.com/api/v1/obtener_clave_productos/${nomCatalogo}/${expresion}`)
        else
            return []
    }
    else {
        res = await fetch(`https://invoice.eksuite.com/api/v1/obtener_catalogo/${nomCatalogo}`)
    }
    
    let data = await res.json()
    return data.data
}

/**
 * 
 * @param {Array} datos 
 * @param {boolean} incluirID
 */
export const adaptarCatalogoSAT = (datos = [], incluirID = false, nomColID = 'id', nomColTexto = 'texto') => {
    return datos.map((dato) => {
        let texto = dato[nomColTexto] ? dato[nomColTexto] : ''

        return {
            id: dato[nomColID],
            text: incluirID ? `${dato[nomColID]} ${ '- '+texto }` : texto,
            item: dato,
        }
    })
}

/**
 * 
 * @param {Array} datos 
 * @param {string} valor 
 */
export const obtenerID = (datos = [], valor = '') => {
    datos = datos.filter((dato) => {
        return dato.text === valor
    })
    
    if(datos.length > 0) return datos[0].id
    else return null
}

/**
 * 
 * @param {Array} datos 
 * @param {string} id
 * @returns string
 */
export const obtenerValor = (datos = [], id = '', mensajeError = '', obtenerItem = false) => {
    datos = datos.filter((dato) => {
        return dato.id === id
    })
    
    if(datos.length > 0) {
        return obtenerItem ? datos[0]?.item : datos[0]?.text
    }
    else {
        return mensajeError
    }
}

/**
 * 
 * @param {string} url
 * @param {string} tipo
 * @param {string} nomDescarga 
 */
export const abrirArchivo = (url = '', tipo = 'abrir', nomDescarga = 'archivo.pdf') => {
    let a = document.createElement('a')
    a.href = url

    if(tipo === 'abrir') a.target = '_blank'
    else a.download = nomDescarga
    
    a.click()
}

/**
 * 
 * @param {string} contenido 
 * @param {string} tipo 
 * @param {boolean} crearURL 
 * @returns 
 */
export const crearArchivoBlob = (contenido = '', tipo = 'text/xml', crearURL = false) => {
    let blob = new Blob([contenido], { type: tipo })
    if(crearURL) return URL.createObjectURL(blob)
    else return blob
}

/**
 * 
 * @param {object} urlOriginal 
 */
export const obtenerURLObjeto = async (urlOriginal = null) => {
    if(urlOriginal === null) return null
    else {
        let archivo = await fetch(urlOriginal)
        archivo = await archivo.blob()
        return URL.createObjectURL(archivo)
    }
}

/**
 * 
 * @param {string} mensaje 
 * @param {function name(params) {}} callbackShowAlert 
 */
const mostrarError = (mensaje = '', callbackShowAlert = () => {}) => {
    callbackShowAlert({
        icono: 'warning',
        titulo: 'Información faltante',
        mensaje: mensaje,
    })
}

/**
 * 
 * @param {object} emisor 
 * @param {object} receptor 
 * @param {Array} conceptos
 * @param {Array} archivos 
 * @param {function name(params) {}} callbackShowAlert 
 */
export const timbrarFactura = async (
    informacion = null,
    emisor = null, 
    receptor = null, 
    conceptos = [], 
    clavesUnidad = [],
    archivos = [], 
    callbackShowAlert = () => {}
) => {
    if(!emisor) {
        mostrarError('Debe completar su información para continuar', callbackShowAlert)
    }
    else if(!receptor) {
        mostrarError('Debe elegir un cliente (receptor) para continuar', callbackShowAlert)
    }
    else if(conceptos.length === 0) {
        mostrarError('Debe elegir un(os) concepto(s) para continuar', callbackShowAlert)
    }
    else if(!informacion?.metodo_pago || !informacion?.tipo_relacion || !informacion?.uso_factura) {
        mostrarError('Debe completar la información restante de la factura para continuar', callbackShowAlert)
    }
    else {
        let logo = null, certificado = null, clavePrivada = null
        for(let i = 0; i < archivos.length; i++) {
            if(archivos[i].carpeta === 'logo') logo = await crearObjetoURL(archivos[i])
            else if(archivos[i].carpeta === 'certificado') certificado = await crearObjetoURL(archivos[i])
            else if(archivos[i].carpeta === 'clave_privada') clavePrivada = await crearObjetoURL(archivos[i])
        }

        let tipoFactura = informacion?.tipo_factura?.length > 0 ? informacion.tipo_factura[0] : 'I' 
        let formData = new FormData()
        formData.append('logo', logo)
        formData.append('key', clavePrivada)
        formData.append('cer', certificado)
        formData.append('fondoLogo', config.colorPrimario)
        formData.append('colorPrimario', config.colorPrimario)
        formData.append('colorTextoPrimario', config.colorBlanco)
        formData.append('colorSecundario', '#FFF')
        formData.append('colorTextoSecundario', config.colorNegro)
        formData.append('urlTimbrado', config.urlTimbrado)
        formData.append('contrasenia', emisor.clave)
        formData.append('emisorRFC', emisor.idfiscal)
        formData.append('emisorNombre', emisor.razonsocial)
        formData.append('regimenSocial', emisor.regimen)
        formData.append('tipoFactura', tipoFactura)
        formData.append('receptorRFC', receptor.idfiscal)
        formData.append('receptorNombre', receptor.razonsocial)
        formData.append('regimenSocialReceptor', receptor.regimen)
        formData.append('domicilioReceptor', receptor.codigopostal)
        formData.append('usoFactura', informacion.uso_factura)
        formData.append('codigoPostal', emisor.codigopostal)
        formData.append('moneda', informacion?.moneda ?? receptor.moneda)
        formData.append('formaPago', informacion?.forma_pago ?? receptor.forma_pago)
        formData.append('metodoPago', informacion.metodo_pago)
        formData.append('serie', informacion.serie)
        formData.append('folio', informacion.folio)
        formData.append('tipoRelacion', informacion.tipo_relacion)
        formData.append('conceptos', JSON.stringify(obtenerConceptos(conceptos, clavesUnidad)))
        formData.append('uuid', informacion?.uuid)

        // for (const entrada of formData.entries()) {
        //     const clave = entrada[0];
        //     const valor = entrada[1];
        //     console.log(`Clave: ${clave}, Valor: ${valor}`);
        // }

        try {
            let response = await fetch(config.urlFacturacion, {
                method: 'POST',
                body: formData
            })
            let data = await response.json()
            if(response.ok) {
                return data
            }
            else {
                console.log(data)
                callbackShowAlert({
                    icono: 'error',
                    titulo: 'Error al Timbrar Factura',
                    mensaje: data.error,
                })
                return null
            }
        }
        catch(e) {
            callbackShowAlert({
                icono: 'error',
                titulo: 'Error al Timbrar Factura',
                mensaje: 'Ocurrio un error por favor llene sus certificados correctamente',
            })
            return null
        }
    }
}

/**
 * 
 * @param {object} emisor 
 * @param {string} uuid 
 * @param {string} motivo 
 * @param {string} folioSustitucion
 */
export const cancelarFactura = async (emisor = null, uuid = '', motivo = '', folioSustitucion = '', archivos = []) => {
    let certificado = null, clavePrivada = null, logo = null
    
    for(let i = 0; i < archivos.length; i++) {
        if(archivos[i].carpeta === 'logo') logo = await crearObjetoURL(archivos[i])
        else if(archivos[i].carpeta === 'certificado') certificado = await crearObjetoURL(archivos[i])
        else if(archivos[i].carpeta === 'clave_privada') clavePrivada = await crearObjetoURL(archivos[i])
    }

    let formData = new FormData()
    formData.append('key', clavePrivada)
    formData.append('cer', certificado)
    formData.append('urlCancelacion', config.urlCancelacion)
    formData.append('rfc', emisor.idfiscal)
    formData.append('contrasenia', emisor.clave)
    formData.append('motivo', motivo)
    formData.append('UUID', uuid)
    formData.append('folioSustitucion', folioSustitucion)
    formData.append('logo', logo)
    formData.append('fondoLogo', config.colorPrimario)
    formData.append('colorPrimario', config.colorPrimario)
    formData.append('colorTextoPrimario', config.colorBlanco)
    formData.append('colorSecundario', config.colorBlanco)
    formData.append('colorTextoSecundario', config.colorNegro)

    let response = await fetch(config.urlCancelarFactura, {
        method: 'POST',
        body: formData
    })
    let data = await response.json()
    return {
        estatus: response.status,
        data: data
    }
}

/**
 * 
 * @param {object} concepto 
 * @returns 
 */
export const calcularValorImpuesto = (concepto = null) => {
    if(concepto) {
        if(concepto.impuesto_tipo === OBJETO_IIMPUESTO) {
            let importe = calcularImporte(concepto)
            let impuestoValor = parseFloat(concepto.impuesto_valor ?? 0)

            return parseFloat(importe * impuestoValor)
        }
        else return 0
    }
    else return 0
}

/**
 * 
 * @param {object} concepto 
 * @returns 
 */
export const calcularImporte = (concepto = null) => {
    if(concepto) {
        let valorUnitario = parseFloat(concepto.valor_unitario ?? 0)
        let cantidad = parseInt(concepto.cantidad ?? 0)
        let importeSinDescuento = parseFloat(valorUnitario * cantidad)
        let descuento = parseInt(concepto.descuento ?? 0)
        let valorDescuento = parseFloat(importeSinDescuento * descuento / 100)
        let importe = parseFloat(importeSinDescuento - valorDescuento)

        return parseFloat(importe)
    }
    else return 0
}

/**
 * 
 * @param {object} concepto 
 * @returns 
 */
export const calcularTotal = (concepto = null) => {
    if(concepto) {
        let importe = calcularImporte(concepto)
        let impuestoValor = parseFloat(concepto.impuesto_valor ?? 0)
        impuestoValor = importe * impuestoValor

        return parseFloat(importe + impuestoValor)
    }
    else return 0
}

/**
 * 
 * @param {Array} conceptosAgregados 
 * @param {Array} clavesUnidad
 * @returns 
 */
const obtenerConceptos = (conceptosAgregados = [], clavesUnidad = []) => {
    let conceptos = [];
    for (let i = 0; i < conceptosAgregados.length; i++) {
        let claveUnidad = obtenerValor(clavesUnidad, conceptosAgregados[i].clave_unidad)
        claveUnidad = claveUnidad.split(' ')
        claveUnidad = claveUnidad.length > 1 ? claveUnidad[2] : claveUnidad[0]

        conceptos.push({
            claveProducto: conceptosAgregados[i].clave_producto,
            claveUnidad: conceptosAgregados[i].clave_unidad,
            cantidad: conceptosAgregados[i].cantidad,
            unidad: claveUnidad,
            descripcion: conceptosAgregados[i]?.descripcion?.length > 0 ? conceptosAgregados[i]?.descripcion : 'Sin descripción',
            valorUnitario: parseFloat(conceptosAgregados[i].valor_unitario),
            importeConcepto: calcularImporte(conceptosAgregados[i]),
            descuento: parseFloat(conceptosAgregados[i].descuento ?? 0),
            base: calcularImporte(conceptosAgregados[i]),
            tipoImpuesto: conceptosAgregados[i].impuesto_clave,
            factores: conceptosAgregados[i].nombre_unidad,
            valor: parseFloat(conceptosAgregados[i].impuesto_valor),
            importeImpuesto: calcularValorImpuesto(conceptosAgregados[i]),
            objetoImpuesto: conceptosAgregados[i].impuesto_tipo,
            // NumeroPedimento: '23  01  3173  3000001',
            // FechaPediento: i === 1 ? '2023' : '2023-05-28'
        });
    }
    return conceptos;
}

/**
 * 
 * @param {object} archivo 
 */
export const crearObjetoURL = async (multimedia = null) => {
    if(multimedia === null) return null
    else {
        let archivo = await fetch(`${config.urlCRUFDEKArchivo}/${multimedia.carpeta}/${multimedia.nombre}`)
        return archivo = await archivo.blob()
        // return URL.createObjectURL(archivo)
    }
}
