import nouser from "../img/nouser.jpg"
import { config } from "../config/config"

export const MESES = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
]

export const agregarCero = (num = 0) => {
    if(num >= 0 && num < 10) {
        return `0${num}`
    }
    else {
        return num.toString()
    }
}

export const capitalize = (cadena = '') => {
    let firstLetter = cadena.charAt(0)
    let firstLetterCap = firstLetter.toUpperCase()
    let remainingLetters = cadena.slice(1)
    return firstLetterCap + remainingLetters
}

export const obtenerFoto = (usuario = {}) => {
    if(usuario?.eureka_cr_multimedia?.length !== 0) {
        let multimedia = usuario.eureka_cr_multimedia[0]
        return `${config.crufdekFileURL}/${multimedia.carpeta}/${multimedia.nombre}`
    }
    else {
        return nouser
    }
}

export const obtenerRol = (usuario = {}) => {
    if(usuario?.eureka_cr_rol_usuarios[0]?.eureka_cr_rol) {
        return usuario.eureka_cr_rol_usuarios[0].eureka_cr_rol.nombre
    }
    else {
        return null
    }
}

export const ocultarNumCuenta = (numero = null) => {
    if(numero) {
        let tamanio = numero.length
        let numeroOculto = ''
        for(let i = 0; i < tamanio; i++) {
            if(i < (tamanio - 2)) {
                numeroOculto += '*'
            }
            else {
                numeroOculto += numero[i]
            }
        }
        return numeroOculto
    }
    else {
        return 'Sin número de cuenta'
    }
}

export const numberFormat = (number = 0.0, decimals = 2, decPoint = '.', thousandsSep = ',', dollarSimb = null) => {
    number = number ? number : 0
    number = Number(number).toFixed(decimals)
    let numberStr = String(number)
    let posDec = numberStr.indexOf('.')
    let numberIntStr = numberStr.slice(0, posDec)
    let decimalsStr = numberStr.slice(posDec).replace('.', decPoint)
    let digitsCount = 0
    let numberFormat = []


    for(let i = numberIntStr.length-1; i >= 0; i--) {
        if(digitsCount === 3) {
            digitsCount = 0
            numberFormat.push(thousandsSep)
        }
        
        digitsCount++
        numberFormat.push(numberIntStr[i])
    }
    numberStr = numberFormat.reverse().join('') + decimalsStr

    if(dollarSimb) {
        return `${dollarSimb}${numberStr}`
    }
    else {
        return numberStr
    }
}

export const adapatarDataTabla = (data =[], config = []) => {
    if(data.length === 0 || config.length === 0) return data
    else {
        return data.map((dato) => {
            let datoAdaptado = {
                ...dato
            }
            let valor = null
            let profundidades = null
            let profundidad = null
            let j = null

            for(let i = 0; i < config.length; i++) {
                profundidades = config[i].profundidad
                
                for(j = 0; j < profundidades.length; j++) {
                    profundidad = profundidades[j]
                    if(valor) {
                        valor = (profundidad === 'array') ? valor.length : valor[profundidad]
                    }
                    else {
                        valor = datoAdaptado[profundidad]
                    }
                }
                datoAdaptado[config[i].nomNuevoCampo] = valor
                valor = null
            }
        
            return datoAdaptado
        })
    }
}

export const dateFormat = (date = new Date(), format = 'db') => {
    if(format === 'db') {
        return `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
    }
    else if(format === 'human') {
        return `${date.getDate()} de ${MESES[date.getMonth()]} del ${date.getFullYear()}`
    }
    else {
        return date.toJSON()
    }
}