import { Tabla, buildData } from "eureka-design"
import { useDispatch } from "react-redux"
import { useObtenerPorcentajesComisionesQuery } from "../../js/services/comision"
import { AdminComision } from "../../js/AdministradorComision"
import { NOM_FORM_PORCENTAJE_COMISION } from "./FormPorcentajeComision"
import { setHandler } from "../../js/feactures/HandlerQuerySlice"

export default function PorcentajeComisionGeneralMax({ ShowAlert, Push }) {
    /** ESTADOS GLOBALES */
    const dispatch = useDispatch()

    /** HOOKS */
    const { data, isLoading, error } = useObtenerPorcentajesComisionesQuery({
        tipo_usuario: AdminComision.EXCEDENTE
    })

    if(error) {
        ShowAlert({
            icono: 'error',
            titulo: 'Ocurrio un Error',
            mensaje: error,
            time: 4
        })
        return <p>[-] Sucedio el siguiente error: {error}</p>
    }
    else if(isLoading) {
        return
        // return <p>Cargando comisiones, por favor espere...</p>
    }
    else {
        return (
            <>
                <Tabla
                    noData="No hay comisiones máximas generales"
                    checkbox={false}
                    rowSize={'medium'}
                    botonesHead={[
                        {
                            icono: "fa-solid fa-plus",
                            tooltip: "Agregar porcentaje comisión",
                            onClick: (item) => {
                                if(data.length === AdminComision.TOTAL_NIVELES) {
                                    ShowAlert({
                                        icono: 'error',
                                        titulo: 'Niveles superados',
                                        mensaje: `El total de niveles registridados supera los ${AdminComision.TOTAL_NIVELES} permitidos`,
                                        time: 4
                                    })
                                }
                                else {
                                    dispatch(setHandler({
                                        state: NOM_FORM_PORCENTAJE_COMISION,
                                        content: { id: null, tipo_usuario: AdminComision.EXCEDENTE }
                                    }))
                                    Push('formComision')
                                }
                            }
                        }
                    ]}
                    botonesRow={[
                        {
                            icono: "fa-solid fa-file-edit",
                            tooltip: "Editar",
                            onClick: (item) => {
                                let props = {}
                                item.content.forEach((c) => {
                                    props[c.key] = c.value ? c.value : c.text
                                })
                                dispatch(setHandler({
                                    state: NOM_FORM_PORCENTAJE_COMISION,
                                    content: { 
                                        id: item.id,
                                        tipo_comision: props['nombre_comision'].replace('Nivel ', ''),
                                        tipo_usuario: AdminComision.EXCEDENTE,
                                        ...props 
                                    }
                                }))
                                Push('formComision') 
                            },
                        },
                        {
                            icono: "fa-solid fa-trash-can",
                            tooltip: "Borrar",
                            onClick: (item) => {
                            },
                        },
                    ]}
                    headers={[
                        { text: 'Nombre', key: 'nombre_comision', type: 'text' },
                        { text: 'Porcentaje', key: 'porcentaje', type: 'text' },
                        { 
                            text: 'Cuota Máxima', 
                            key: 'fijo', 
                            type: 'precio',
                            value: (item) => parseFloat(item.fijo),
                        },
                    ]}
                    data={buildData(data, [
                        { key: "nombre_comision", type: "text" },
                        { 
                            key: "porcentaje", 
                            type: "text",
                            value: (item) => parseInt(item.porcentaje),
                            text: (item) => `${Number.isInteger(parseInt(item.porcentaje)) ? item.porcentaje : 0}%`,
                        },
                        { key: "fijo", type: "precio" },
                    ])}
                >
                </Tabla>
            </>
        )
    }

}