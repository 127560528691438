import { ModalForm, DatePicker, Select } from "eureka-design"
import { useState } from "react"
import { agregarCero } from "../../js/util"

const ANIO_MIN = 2023
const ANIO_MAX = 2100
const TIPOS_MOVIMIENTOS = [{text: 'Afiliación', value: 'afiliación'}, {text: 'Compra', value: 'compra'}]

const ModalDatePicker = ({ 
    mostrar = true, 
    onClose = () => {}, 
    onChange = () => {},
    ShowAlert = () => {}
}) => {
    const [tipo, fijaTipo] = useState("")
    const [fechaInicio, fijaFechaInicio] = useState("")
    const [fechaFin, fijaFechaFin] = useState("")

    const handlerBuscar = () => {
        if(tipo?.length > 0 && fechaInicio?.length > 0 && fechaFin?.length > 0) {
            onChange(fechaInicio, fechaFin, tipo)
            onClose(false)
        }
        else {
            ShowAlert({
                icono: 'warning',
                titulo: 'Falta Información',
                mensaje: 'Complete los datos para poder buscar las ventas.',
                time: 4
            })
        }
    }

    return (
        <ModalForm 
            titulo="Generar Reporte" 
            show={mostrar} 
            onClose={() => { onClose(false) }} 
            botones={[
                {
                    iconLeft: 'fa-magnifying-glass',
                    texto: 'Buscar Ventas',
                    onClick: handlerBuscar
                }
            ]}
        >
            <div style={{ display: 'contents' }}>
                <Select
                    iconLeft="fa-tags"
                    placeholder="Tipo"
                    options={TIPOS_MOVIMIENTOS}
                    changeFunction={(val) => { fijaTipo(val?.value) }}
                    value="Seleccione un tipo de venta..."
                />
                <div style={{ display: 'flex', justifyContent: 'center', position: 'relative', zIndex: 1 }}>
                    <DatePicker 
                        rangePicker={true}
                        timePicker={true}
                        minYear={ANIO_MIN}
                        maxYear={ANIO_MAX}
                        onChange={(inicio = null, fin = null) => {
                            if(inicio && fin) {
                                inicio = `${inicio.YYYY}-${inicio.mm}-${agregarCero(inicio.D)} ${agregarCero(inicio.HH)}:${agregarCero(inicio.Mm)}`
                                fin = `${fin.YYYY}-${fin.mm}-${agregarCero(fin.D)} ${agregarCero(fin.HH)}:${agregarCero(fin.Mm)}`
                                fijaFechaInicio(inicio)
                                fijaFechaFin(fin)
                            }
                            // else no procesar
                        }}
                    />
                </div>
            </div>
        </ModalForm>
    )
}

export default ModalDatePicker