import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { config } from "../../config/config"
import { EkQuery } from "../query"
// import { AdminComision } from "../AdministradorComision"
import { token } from "account-react-eurekasigma"

const TABLA_MOVIMIENTOS = 'EurekaStTransaccione'
const TABLA_CONCEPTOS = 'EurekaStTransaccionesConcepto'
const TABLA_VENDEDORES = "EurekaAcUsuario"
const TABLA_DETALLES = 'EurekaStTransaccionesDetalle'
const TABLA_COMISION_PERIODO = 'EurekaStComisionesPeriodo'
const TABLA_MOVIMIENTO_COMISIONISTA = 'EurekaStTransaccionesComisionista'
const TABLA_MOVIMIENTO_PERIODO = 'EurekaStTransaccionesPeriodo'

export const movimientoApi = createApi({
    reducerPath: 'movimientoApi',
    baseQuery: fetchBaseQuery({ baseUrl: config.crufdekURL }),
    tagTypes: ['Movimientos'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        obtenerMovimientos: builder.query({
            query: (data) => {
                let body = new EkQuery(TABLA_MOVIMIENTOS)
                if(data.rangoFechas && data.tipoMovimientos) {
                    let fechaInicio = data.rangoFechas.startDate
                    let fechaFin = data.rangoFechas.endDate
                    // fechaFin.setDate(fechaFin.getDate() + 1)
                    fechaInicio = `${fechaInicio.getFullYear()}-${fechaInicio.getMonth()+1}-${fechaInicio.getDate()} ${fechaInicio.getHours()}:${fechaInicio.getMinutes()}:${fechaInicio.getSeconds()}`
                    fechaFin = `${fechaFin.getFullYear()}-${fechaFin.getMonth()+1}-${fechaFin.getDate()} ${fechaFin.getHours()}:${fechaFin.getMinutes()}:${fechaFin.getSeconds()}`

                    body = body
                            .where('cxp_cxc', '!=', 'archivado')
                            .where('tipo', '!=', 'registro_excel')
                            .where('tipo', '=', data.tipoMovimientos)
                            .where('cxp_cxc', '=', 'liquidado')
                            .where('fecha', '>=', fechaInicio)
                            .where('fecha', '<=', fechaFin)
                            .with('eureka_st_transacciones_conceptos')
                            .with('eureka_st_transacciones_detalles')
                            .with('eureka_st_transacciones_conceptos.eureka_st_producto.eureka_st_productos_precios')
                            .with('eureka_ac_usuario.eureka_bs_contactos.eureka_ac_usuario_contacto')
                            .with('eureka_ac_usuario.eureka_st_transacciones')
                }
                else {
                    body = body
                            .where('cxp_cxc', '!=', 'archivado')
                            .where('tipo', '!=', 'excedente')
                            .where('tipo', '!=', 'registro_excel')
                            .with('eureka_st_transacciones_conceptos')
                            .with('eureka_st_transacciones_detalles')
                            .with('eureka_st_transacciones_conceptos.eureka_st_producto.eureka_st_productos_precios')
                            .with('eureka_ac_usuario.eureka_bs_contactos.eureka_ac_usuario_contacto')
                            .with('eureka_ac_usuario.eureka_st_transacciones')
                }

                if(data.vendedor) {
                    body.where('vendedor', data.vendedor)
                }

                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body.get()
                }
            }
        }),
        obtenerMovimiento: builder.query({
            query: (data) => {
                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: new EkQuery(TABLA_MOVIMIENTOS)
                            .with('eureka_st_comisiones')
                            .with('eureka_st_transacciones_conceptos.eureka_st_producto.eureka_st_productos_precios')
                            .with('eureka_ac_usuario.eureka_bs_contactos.eureka_ac_usuario_contacto')
                            .with('eureka_ac_usuario.eureka_st_transacciones')
                            .where('id', '=', data.id)
                            .first()
                }
            },
        }),
        crearMovimiento: builder.mutation({
            /**
             * 
             * @param {{ id: number, body: object }} data 
             * @returns 
             */
            query: (data) => {
                let body = null
                if (data?.id) {
                    body = new EkQuery(TABLA_MOVIMIENTOS)
                                .where("id", data.id)
                                .update(data.body)
                }
                else body = new EkQuery(TABLA_MOVIMIENTOS).create(data.body)

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
        obtenerConceptos: builder.query({
            query: (data) => {
                let id = data.transaccionID ? data.transaccionID : 0

                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: new EkQuery(TABLA_CONCEPTOS)
                            .where('transaccion', '=', id)
                            .with('eureka_st_producto.eureka_st_productos_precios')
                            .get()
                }
            },
        }),
        crearConcepto: builder.mutation({
            query: (data) => {
                let body = null
                if (data.id) {
                    body = new EkQuery(TABLA_CONCEPTOS)
                                .where("id", data.id)
                                .update(data.body)
                }
                else body = new EkQuery(TABLA_CONCEPTOS).create(data.body)

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
        obtenerVendedor: builder.mutation({
            query: (data) => {
                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: new EkQuery(TABLA_VENDEDORES)
                                .where('id', '=', data.id)
                                .with('eureka_bs_contactos')
                                .first()
                }
            },
        }),
        crearDetalle: builder.mutation({
            query: (data) => {
                let body = null
                if (data.id) {
                    body = new EkQuery(TABLA_DETALLES)
                                .where("id", data.id)
                                .update(data.body)
                }
                else body = new EkQuery(TABLA_DETALLES).create(data.body)

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
        obtenerNumerosReferencias: builder.query({
            query: (data) => {
                let body = new EkQuery(TABLA_MOVIMIENTOS)

                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body.get()
                }
            },
            transformResponse: (data) => {
                data = data.map((item) => {
                    return item.token_stripe
                })
                data = data.filter((item) => {
                    return item !== null
                })
                return data
            },
        }),
        crearPeriodoReporte: builder.mutation({
            query: (data) => {
                let body = new EkQuery(TABLA_MOVIMIENTO_PERIODO)
                if(data.id) {
                    body = body
                            .where('id', data.id)
                            .update(data.body)
                }
                else {
                    body = body.create(data.body)
                }

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
        crearRelacionReporte: builder.mutation({
            query: (data) => {
                let body = new EkQuery(TABLA_COMISION_PERIODO)
                if(data.id) {
                    body = body
                            .where('id', data.id)
                            .update(data.body)
                }
                else {
                    body = body.create(data.body)
                }

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
        crearComisionista: builder.mutation({
            query: (data) => {
                let body = new EkQuery(TABLA_MOVIMIENTO_COMISIONISTA)
                if(data.id) {
                    body = body
                            .where('id', data.id)
                            .update(data.body)
                }
                else {
                    body = body.create(data.body)
                }

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
        obtenerPeriodoReporte: builder.query({
            query: (data) => {
                let body = new EkQuery(TABLA_MOVIMIENTO_PERIODO)
                                .with('eureka_st_comisiones_periodo.eureka_st_transaccion.eureka_ac_usuario')
                                .with('eureka_st_comisiones_periodo.eureka_ac_usuario')

                if(data?.vendedor) {
                    body = body.where('total', data.vendedor).get()
                }
                else {
                    body = body.get()
                }

                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
        obtenerComisionistas: builder.query({
            query: (data) => {
                let body = new EkQuery(TABLA_MOVIMIENTO_COMISIONISTA).get()

                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
    })
})

export const {
    useObtenerMovimientosQuery,
    useCrearMovimientoMutation,
    useObtenerConceptosQuery,
    useCrearConceptoMutation,
    useObtenerMovimientoQuery,
    useObtenerVendedorMutation,
    useCrearDetalleMutation,
    useObtenerNumerosReferenciasQuery,
    useCrearPeriodoReporteMutation,
    useCrearRelacionReporteMutation,
    useCrearComisionistaMutation,
    useObtenerPeriodoReporteQuery,
    useObtenerComisionistasQuery,
} = movimientoApi