import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { config } from "../../config/config"
import { EkQuery } from "../query"
import { token } from "account-react-eurekasigma"

const TABLA_VENTAS = 'EurekaStTransaccione'

export const ventaAPI = createApi({
    reducerPath: 'ventaAPI',
    baseQuery: fetchBaseQuery({ baseUrl: config.crufdekURL }),
    tagTypes: ['Ventas'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        obtenerVentas: builder.query({
            query: () => {
                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: new EkQuery(TABLA_VENTAS)
                            .where('cxp_cxc', '!=', 'archivado')
                            .where('tipo',    '!=', 'registro_excel')
                            .with('eureka_st_transacciones_conceptos.eureka_st_producto')
                            .with('eureka_st_comisiones.eureka_ac_comisionista')
                            .with('eureka_ac_usuario')
                            .get()
                }
            },
        }),
    })
})

export const {
    useObtenerVentasQuery,
} = ventaAPI