import { agregarCero } from "../util"

export class Reporte {
    AFILIADO = 'Afiliado'
    COMPRA = 'Compra'
    EXCEDENTE = 'Excedente'

    constructor(
        obtenerVendedor = () => {},
        crearVenta = () => {}, 
        crearComision = () => {}, 
        crearReporte = () => {}, 
        obtenerNiveles = () => {},
        relacionReporteComision = () => {},
        obtenerPadre = () => {},
        administrador = {},
        fechaInicio = '',
        fechaFin = ''
    ) {
        this.obtenerVendedor = obtenerVendedor
        this.crearventa = crearVenta
        this.crearReporte = crearReporte
        this.crearComision = crearComision
        this.obtenerNiveles = obtenerNiveles
        this.relacionReporteComision = relacionReporteComision
        this.obtenerPadre = obtenerPadre
        this.administrador = administrador
        this.fechaInicio = fechaInicio
        this.fechaFin = fechaFin
    }

    async cargarNiveles() {
        this.nivelesAfiliado = await this.obtenerNiveles({ tipo_usuario: this.AFILIADO }).unwrap().then(res => { return res?.Afiliado ?? [] })
        this.nivelesCompra = await this.obtenerNiveles({ tipo_usuario: this.COMPRA }).unwrap().then(res => { return res?.Compra ?? [] })
        this.nivelesExcedente = await this.obtenerNiveles({ tipo_usuario: this.EXCEDENTE }).unwrap().then(res => { return res?.Excedente ?? [] })
        return true
    }

    /**
     * @param {object} venta 
     * @param {string} estatus 
     */
    cambiarEstatusVenta(venta = null, estatus = 'pagar') {
        if(venta) {
            this.crearventa({
                id: venta.id,
                body: {
                    cxp_cxc: estatus
                }
            })
        }
        // else no cambiar estatus
    }

    /**
     * @param {string} inicio 
     * @param {string} fin 
     * @param {string} tipoVenta 
     * @param {object} usuario
     */
    async inicializarReporte(inicio = '', fin = '', tipoVenta = '', usuario = null) {
        if(inicio?.length > 0 && fin?.length > 0 && tipoVenta?.length > 0) {
            let nomReporte = `Reporte_${inicio.replace(' ', '_')}_${fin.replace(' ', '_')}_${tipoVenta.toLowerCase()}`
            this.crearReporte({
                id: null,
                body: {
                    nombre: nomReporte,
                    tipo: tipoVenta,
                    fecha_inicio: inicio,
                    fecha_final: fin,
                    total: usuario.id
                }
            })
            .unwrap()
            .then((res) => { this.reporte = res?.mensaje })
        }
        // else no se creo el reporte
    }

    /**
     * @param {object} venta 
     * @param {object} comisionista
     * @param {number} total
     */
    relacionReporteVenta(venta = null, comisionista = null, total = 0) {
        if(venta) {
            this.relacionReporteComision({
                body: {
                    periodo: this.reporte.id,
                    transaccion: venta.id,
                    vendedor: comisionista.id,
                    total: total.toFixed(2)
                }
            })
        }
        // else no relacionar reporte con la venta
    }

    /**
     * @param {Array} conceptos 
     */
    calcularTotalVenta(conceptos = []) {
        let total = 0
        conceptos.forEach((concepto) => {
            total += parseFloat(concepto.costo) * parseFloat(concepto.cantidad)
        })
        return parseFloat(total)
    }

    /**
     * @param {string} fechaInicio
     * @param {string} fechaFin
     * @param {Array} ventas 
     */
    calcularTotalVentas(ventas = []) {
        let total = 0
        let fecha = null

        ventas.forEach((venta) => {
            fecha = new Date(venta.fecha)
            fecha = `${fecha.getFullYear()}-${agregarCero(fecha.getMonth()+1)}-${agregarCero(fecha.getDate())} ${agregarCero(fecha.getHours())}:${agregarCero(fecha.getMinutes())}`
            
            if(fecha >= this.fechaInicio && fecha <= this.fechaFin && (venta.cxp_cxc === 'liquidado' || venta.cxp_cxc === 'pagar')) {
                total += this.calcularTotalVenta(venta.eureka_st_transacciones_conceptos)
            }
            else {
                total += 0
            }
        })
        return parseFloat(total)
    }

    /**
     * @param {number} porcentaje
     * @param {Array} conceptos 
     */
    calcularTotalComision(porcentaje = 0, conceptos = []) {
        let total = 0
        let producto = null

        conceptos.forEach((concepto) => {
            producto = concepto?.eureka_st_producto
            total += parseFloat(producto.costo) * parseFloat(porcentaje) / 100.0
        })
        
        return parseFloat(total)
    }


    /**
     * @param {string} tipo 
     * @param {object} nivel 
     * @param {object} venta 
     * @param {object} vendedor 
     * @param {object} comisionista
     */
    handlerCrearComision(tipo = '', nivel = null, venta = null, vendedor = null, comisionista = null, esExcedente = false) {
        if(tipo?.length > 0 && nivel && venta && vendedor && comisionista) {
            let total = 0
            if(esExcedente) {
                total = parseFloat(venta?.total) * parseFloat(nivel.porcentaje) / 100.0
            }
            else {
                total = this.calcularTotalComision(parseFloat(nivel.porcentaje), venta?.eureka_st_transacciones_conceptos)
            }

            this.relacionReporteVenta(venta, comisionista, total)
            this.crearComision({
                id: null,
                body: {
                    nombre_comision: `Comision ${nivel.porcentaje}%`,
                    transaccion: venta.id,
                    comisionista: comisionista.id,
                    porcentaje: nivel.porcentaje,
                    comision: total.toFixed(2),
                }
            })
            return true
        }
        else return false
    }

    /**
     * @param {Array} niveles 
     * @param {object} venta 
     * @param {object} vendedor 
     */
    async procesarNiveles(niveles = [], venta = {}, vendedor = {}, esExcedente = false) {
        let hijo = vendedor
        let padre = await this.obtenerPadre({ usuario: hijo.id }).unwrap().then(res => res?.eureka_ac_usuario_contacto)
        let nivel = null
        let totalVentas = 0
        let i = 0
        
        for(i = 0; i < niveles.length; i++) {
            nivel = niveles[i]

            if(i === 0 && esExcedente) {
                console.log('Dar comision al vendedor...')
                this.handlerCrearComision('activo', nivel, venta, vendedor, vendedor, esExcedente)
            }
            else if(padre) {
                totalVentas = this.calcularTotalVentas(padre?.eureka_st_transacciones)

                if(totalVentas === 0) { // Vendedor Inactivo
                    console.log('Pasar al siguiente usuario en la escalera...')
                    padre = await this.obtenerPadre({ usuario: padre.id }).unwrap().then(res => res?.eureka_ac_usuario_contacto)
                    i -= 1
                }
                if(totalVentas < parseFloat(nivel.fijo)) { // Vendedor No Cuota
                    console.log('Dar comision administrador...')
                    this.handlerCrearComision('activo', nivel, venta, vendedor, this.administrador, esExcedente)
                }
                else { // Vendedor Activo
                    console.log('Dar comision al padre...')
                    this.handlerCrearComision('activo', nivel, venta, vendedor, padre, esExcedente)
                    padre = await this.obtenerPadre({ usuario: padre.id }).unwrap().then(res => res?.eureka_ac_usuario_contacto)
                }
            }
            else {
                i = niveles.length
            }
        }

        this.cambiarEstatusVenta(venta, 'pagar')
    }

    procesarExcedente(total = 0, niveles =[], vendedor = null) {
        this.crearventa({
            body: {
                cxp_cxc: 'liquidado',
                vendedor: vendedor.id,
                total: total,
                tipo: 'excedente'
            }
        })
        .unwrap()
        .then((res) => {
            let venta = res?.mensaje
            if(venta) {
                this.procesarNiveles(niveles, venta, vendedor, true)
            }
            // else no se creo la venta
        })
    }

    /**
     * @param {string} fechaInicio
     * @param {string} fechaFin
     * @param {object} venta
     */
    async calcularComisiones(venta = {}) {
        let vendedor = venta?.eureka_ac_usuario
        let conceptos = venta?.eureka_st_transacciones_conceptos
        let tipoVenta = venta?.tipo

        if(vendedor && conceptos?.length > 0) {
            let niveles = []
            let nivelesExcedente = []

            if(tipoVenta === 'afiliación') niveles = this.nivelesAfiliado
            else if(tipoVenta === 'compra') {
                niveles = this.nivelesCompra
                nivelesExcedente = this.nivelesExcedente
                
                let nivelExcedente = nivelesExcedente?.length > 0 ? nivelesExcedente[0] : null
                let cuotaMaxima = 0
                let totalVenta = 0
                if(nivelExcedente) {
                    cuotaMaxima = parseFloat(nivelExcedente.fijo)
                    vendedor = await this.obtenerVendedor({ id: vendedor.id }).unwrap().then(res => res)
                    totalVenta = this.calcularTotalVentas(vendedor.eureka_st_transacciones)

                    if(totalVenta > cuotaMaxima) {
                        let sobrante = totalVenta - cuotaMaxima
                        this.procesarExcedente(sobrante, niveles, vendedor)
                    }
                    // else no supero la cuota máxima
                }
                // else no hay nivel para excedentes
            }
            else {
                console.log('Tipo de venta desconocida...')
            }
            this.procesarNiveles(niveles, venta, vendedor)
            return true
        }
        else return false
    }
}