import { MiPerfil } from "eureka-design";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useObtenerDistribuidorQuery } from "../../js/services/distribuidor";
import { capitalize, obtenerFoto } from "../../js/util";
import { token } from "account-react-eurekasigma";

export const NOM_DISTRIBUIDOR_ID = 'distribuidorID'

export default function Distribuidor({ Push }) {
    const distribuidorID = useSelector(state => state.handlerQuerySlice[NOM_DISTRIBUIDOR_ID])
    const [id] = useState(distribuidorID.id)
    const { data: distribuidor, isLoading, error } = useObtenerDistribuidorQuery({
        col: 'id', 
        val: id, 
        token: token()
    })

    if(error) return <p>Sucedion un error: {error}</p>
    else if(isLoading) return
    else {
        let informacionExtra = [
            {
                titulo: "Puesto",
                contenido: distribuidor?.eureka_cr_rol_usuarios[0]?.eureka_cr_rol?.nombre
            }
        ]

        distribuidor.eureka_bs_contactos.map((contacto, index) => {
            let usuario = contacto.eureka_ac_usuario_contacto
            informacionExtra.push({
                titulo: capitalize(contacto?.relacion),
                contenido: capitalize(usuario?.nombre?.toLowerCase()),
            })
            return true
        })
        distribuidor.eureka_bs_contactos_contacto.map((contacto, index) => {
            let usuario = contacto.eureka_ac_usuario
            informacionExtra.push({
                titulo: contacto?.relacion === 'abuelo' ? 'Nieto' : 'Hijo',
                contenido: capitalize(usuario?.nombre?.toLowerCase()),
            })
            return true
        })


        return ( 
            <div>
                <MiPerfil
                    avatar={obtenerFoto(distribuidor)}
                    nombre={distribuidor?.nombre?.toLowerCase()}
                    correo={distribuidor?.correo}
                    telefono={distribuidor?.telefono ?? 'Sin teléfono'}
                    extrasInfo={informacionExtra}
                >
                </MiPerfil>
            </div>
        )
    }
}