import { Tabla, buildData } from "eureka-design"
import { numberFormat, obtenerRol } from "../../js/util"
import { useEffect, useState } from "react"

export const NOM_FORM_REPORTE = 'reporte'
export const STATE_COMISIONES_REPORTE = 'comisiones_reporte'
const REGISTROS_POR_PAGINA = 20

const DetalleComisiones = ({ usuario, reporte }) => {
    const [total, fijaTotal] = useState(0)

    const soyAdministrador = obtenerRol(usuario) === 'administrador'
    const periodos = reporte.eureka_st_comisiones_periodo

    let headers = [
        { text: 'Comisionador', key: 'nombre', type: 'text' },
        { text: 'Comisión', key: 'comision', type: 'precio' },
    ]
    let rowData = [
        { 
            key: 'nombre', 
            type: 'text',
            value: (item) => item.eureka_ac_usuario?.nombre ?? null,
            text: (item) => item.eureka_ac_usuario?.nombre ?? 'Ningúno',
        },
        { 
            key: 'comision', 
            type: 'precio',
            value: (item) => {
                return parseFloat(item.total ?? 0)
            }
        },
    ]
    let colComisionista = null
    let valComisionista = null
    if(soyAdministrador) {
        colComisionista = { text: 'Otorgador', key: 'otorgador', type: 'text' }
        valComisionista = { 
            key: 'otorgador', 
            type: 'text',
            value: (item) => {
                return item?.eureka_st_transaccion?.eureka_ac_usuario?.nombre
            },
            text: (item) => {
                return item?.eureka_st_transaccion?.eureka_ac_usuario?.nombre
            },
        }
        headers.push(colComisionista)
        rowData.push(valComisionista)
    }
    // else no mostrar los datos del comisionisa

    useEffect(() => {
        let total = 0

        periodos.forEach((periodo) => {
            total += parseFloat(periodo.total)
        })

        fijaTotal(total)
    }, [periodos])

    return (
        <>
            <p style={{
                width: '100%',
                padding: 10,
                margin: 15,
                textAlign: 'center',
                borderRadius: 10,
                fontSize: 18,
            }}>
                TOTAL DE COMISIONES: <b>{
                    numberFormat(total, 2, '.', ',', '$')
                }</b>
            </p>
            
            <Tabla
                noData="No se dieron algún tipo de comisiones para este reporte"
                checkbox={false}
                paginado={{
                    paginas: Math.round(periodos.length / REGISTROS_POR_PAGINA),
                    paginaCurrent: 1,
                    registrosCurrent: periodos.length < REGISTROS_POR_PAGINA ? periodos.length : REGISTROS_POR_PAGINA,
                    registros: periodos.length,
                    onNext: () => {},
                    onPrev: () => {},
                }}
                rowSize={'medium'}
                headers={headers}
                data={buildData(periodos, rowData)}
            >
            </Tabla>
        </>
    )
}

export default DetalleComisiones