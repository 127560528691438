import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { config } from "../../config/config"
import { EkQuery } from "../query"
import { token } from 'account-react-eurekasigma'

const TABLA_DISTRIBUIDORES = "EurekaAcUsuario"
const TABLA_ROLES = "EurekaCrRol"
const TABLA_ROLES_USUARIOS = "EurekaCrRolUsuario"
const TABLA_CONTACTOS = "EurekaBsContacto"
const TABLA_MULTIMEDIA = "EurekaCrMultimedia"
const ROL = "distribuidor"

let queryData = null

export const distribuidorApi = createApi({
    reducerPath: 'distribuidorApi',
    baseQuery: fetchBaseQuery({ baseUrl: config.crufdekURL }),
    tagTypes: ['Distribuidores'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        obtenerDistribuidor: builder.query({
            query: ({ col, val, token }) => {
                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: new EkQuery(TABLA_DISTRIBUIDORES)
                                .where(col, '=', val)
                                .with('eureka_cr_rol_usuarios.eureka_cr_rol')
                                .with('eureka_bs_contactos.eureka_ac_usuario_contacto.eureka_cr_multimedia')
                                .with('eureka_bs_contactos_contacto.eureka_ac_usuario.eureka_cr_multimedia')
                                .with('eureka_pa_cuenta_fiscals')
                                .with('eureka_cr_multimedia')
                                .with('eureka_st_transacciones')
                                .with('eureka_pa_tarjetas')
                                .first()
                }
            },
        }),
        obtenerDistribuidorComision: builder.mutation({
            query: (data) => {
                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: new EkQuery(TABLA_DISTRIBUIDORES)
                                .where('id', '=', data.id)
                                .with('eureka_cr_rol_usuarios.eureka_cr_rol')
                                .with('eureka_bs_contactos.eureka_ac_usuario_contacto.eureka_cr_multimedia')
                                .with('eureka_bs_contactos_contacto.eureka_ac_usuario.eureka_cr_multimedia')
                                .with('eureka_cr_multimedia')
                                .with('eureka_st_transacciones')
                                .with('eureka_pa_tarjetas')
                                .first()
                }
            },
        }),
        obtenerDistribuidores: builder.query({
            /**
             * @param {{ col: string, val: string }} data 
             */
            query: (data) => {
                queryData = data
                let body = new EkQuery(TABLA_DISTRIBUIDORES)
                                .with('eureka_cr_rol_usuarios.eureka_cr_rol')
                                .with('eureka_pa_tarjetas')
                                .with('eureka_bs_contactos.eureka_ac_usuario_contacto')
                                .with('eureka_cr_multimedia')
                                .with('eureka_st_transacciones_comisiones.eureka_st_transaccion')
                                .get()

                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body

                }
            },
            transformResponse: (response) => {
                const { col, val } = queryData
                
                let distribuidores =  response.filter((distribuidor) => {
                    let rol = distribuidor.eureka_cr_rol_usuarios[0]?.eureka_cr_rol

                    if(col && val) {
                        return rol?.nombre === ROL && distribuidor[col].toLowerCase().includes(val.toLowerCase())
                    }
                    else {
                        return rol?.nombre === ROL
                    }
                })
                return distribuidores
            },
        }),
        crearDistribuidor: builder.mutation({
            query: (data) => {
                let body = null
                if (data.id !== null) {
                    body = new EkQuery(TABLA_DISTRIBUIDORES)
                                .where("id", data.id)
                                .update(data.body)
                }
                else {
                    body = new EkQuery(TABLA_DISTRIBUIDORES)
                                .create(data.body)
                }

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
        obtenerRolDistribuidor: builder.query({
            query: (data) => {
                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: new EkQuery(TABLA_ROLES).get()
                }
            },
            transformResponse: (response) => {
                return response.filter(e => e.nombre === ROL)
            },
        }),
        crearRolDistribuidor: builder.mutation({
            query: (data) => {
                let body = new EkQuery(TABLA_ROLES_USUARIOS)
                                .create({
                                    rol: data.rolID,
                                    usuario: data.usuarioID
                                })

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
        crearApadrinamiento: builder.mutation({
            query: (data) => {
                let body = null
                if(data.id) {
                    body = new EkQuery(TABLA_CONTACTOS)
                            .where("id", data.id)
                            .update({
                                id: data.id,
                                relacion: data.relacion,
                                estatus: 'activo',
                                usuario: data.usuario,
                                contacto: data.contacto,
                            })
                }
                else {
                    body = new EkQuery(TABLA_CONTACTOS)
                            .create({
                                relacion: data.relacion,
                                estatus: 'activo',
                                usuario: data.usuario,
                                contacto: data.contacto,
                            })
                }

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
        actualizarFoto: builder.mutation({
            query: (data) => {
                let body = null
                if (data.id) {
                    body = new EkQuery(TABLA_MULTIMEDIA)
                        .where("id", data.id)
                        .uploadFile(data.file, data.carpeta, data.nombre)
                }
                else {
                    body = new EkQuery(TABLA_MULTIMEDIA)
                        .uploadFile(data.file, data.carpeta, data.nombre)
                }

                let formulario = new FormData();
                Object.entries(body).forEach(([key, value]) => {
                    formulario.append(key, value);
                })

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    headers: { 'Authorization' : `Bearer ${token()}` },
                    enctype: "multipart/form-data",
                    body: formulario
                }
            },
        }),
        relacionarFoto: builder.mutation({
            query: (data) => {
                let body = new EkQuery(TABLA_MULTIMEDIA)
                    .where('id', data.id)
                    .update({
                        usuario: data.usuario
                    })

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
        obtenerFotoPerfil: builder.mutation({
            query: (data) => {
                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: new EkQuery(TABLA_MULTIMEDIA)
                            .where('id', data.id)
                            .first()
                }
            }
        }),
        obtenerPadrino: builder.mutation({
            query: (data) => {
                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: new EkQuery(TABLA_DISTRIBUIDORES)
                                .where('id', '=', data.id)
                                .with('eureka_bs_contactos.eureka_ac_usuario_contacto')
                                .first()
                }
            },
        }),
    })
})

export const {
    useObtenerDistribuidorQuery,
    useObtenerDistribuidorComisionMutation,
    useObtenerDistribuidoresQuery,
    useCrearDistribuidorMutation,
    useObtenerRolDistribuidorQuery,
    useCrearRolDistribuidorMutation,
    useCrearApadrinamientoMutation,
    useActualizarFotoMutation,
    useRelacionarFotoMutation,
    useObtenerFotoPerfilMutation,
    useObtenerPadrinoMutation,
} = distribuidorApi