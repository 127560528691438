import { Formulario, Input, ImageInput } from "eureka-design"
import { useEffect, useState } from "react"
import { crearCookie } from "account-react-eurekasigma"
import { 
    useCrearDistribuidorMutation, 
    useActualizarFotoMutation, 
    useRelacionarFotoMutation, 
    useObtenerFotoPerfilMutation
} from "../../js/services/distribuidor"
import { obtenerFoto } from "../../js/util"
import { config } from "../../config/config"
import { usuario as infoUsuario } from "account-react-eurekasigma"

export default function EditarPerfil({ ShowAlert, mensaje = null }) {
    const usuario = infoUsuario()

    const [mostrarMensaje, fijaMostrarMensaje] = useState(mensaje ? true : false)
    const [fotoPerfil] = useState(obtenerFoto(usuario))
    const [fotoID, fijaFotoID] = useState(usuario?.eureka_cr_multimedia[0]?.id)
    const [usuarioID] = useState(usuario.id)

    const [obtenerMultimedia]  = useObtenerFotoPerfilMutation()
    const [editarPerfil, {
        isLoading: cargandoInput,
        isError: inputErroneo,
        isSuccess: inputActualizado
    }] = useCrearDistribuidorMutation()
    const [editarFoto, {
        isLoading: cargandoFoto,
        isError: fotoErronea,
        isSuccess: fotoActualizada
    }] = useActualizarFotoMutation()
    const [relacionarFoto] = useRelacionarFotoMutation()

    useEffect(() => {
        if(mostrarMensaje) {
            fijaMostrarMensaje(false)
            ShowAlert({
                icono: 'warning',
                titulo: 'Espera un Momento',
                mensaje: mensaje,
                time: 4
            })
        }
        // else no mostrar mensaje
    })

    async function handlerPerfil(nom, val) {
        return await editarPerfil({
                id: usuarioID,
                body: { [nom]: val }
            })
            .unwrap()
            .then((response) => { return 1 })
    }

    async function handlerFoto(e) {
        return await editarFoto({
            id: fotoID,
            file: e.target.files[0],
            carpeta: 'camvi432_usuario',
            nombre: 'nombre',
        })
        .unwrap()
        .then((response) => {
            if (response?.mensaje?.id) {
                fijaFotoID(response.mensaje.id)
                relacionarFoto({ id: response.mensaje.id, usuario: usuarioID })
                let usuarioEditado = { 
                    ...usuario,
                    eureka_cr_multimedia: [response.mensaje]
                }
                crearCookie(config.nomCookieUsuario, JSON.stringify(usuarioEditado), true, config.expCookie)
            }
            else {
                obtenerMultimedia({ id: fotoID })
                .unwrap()
                .then((res) => {
                    let usuarioEditado = { 
                        ...usuario,
                        eureka_cr_multimedia: [res]
                    }
                    crearCookie(config.nomCookieUsuario, JSON.stringify(usuarioEditado), true, config.expCookie)
                })
            }
        })
    }

    const handlerInput = (val, nom) => {
        handlerPerfil(nom, val)
        handlerFormData(nom, val)
    }

    const handlerFormData = (key, val) => {
        let usuarioEditado = {
            ...usuario,
            [key]: val
        }
        crearCookie(config.nomCookieUsuario, JSON.stringify(usuarioEditado), true, config.expCookie)
    }

    return (
        <Formulario 
            titulo={'Información de perfil'}
            isLoading={[cargandoInput, cargandoFoto]}
            isError={[inputErroneo, fotoErronea]}
            isSuccess={[inputActualizado, fotoActualizada]}
        >
            <ImageInput 
                id={'fotoPerfil'}
                preview={fotoPerfil}
                avatar={true}
                isLoading={cargandoFoto}
                changeFunction={handlerFoto}
                width={200}
                height={200}
            />
            <Input 
                nombre="nombre"
                placeholder="Nombre"
                value={usuario.nombre}
                isLoading={cargandoInput}
                changeFunction={handlerInput}
            />
            <Input 
                nombre="correo"
                placeholder="Correo"
                value={usuario.correo}
                disabled={true}
            />
            <Input 
                nombre="telefono"
                placeholder="Teléfono"
                value={usuario.telefono}
                isLoading={cargandoInput}
                changeFunction={handlerInput}
            />
        </Formulario>
    )
}