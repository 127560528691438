import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { config } from "../../config/config"

export const dataBaseApi = createApi({
    reducerPath: 'dataBaseApi',
    baseQuery: fetchBaseQuery({ baseUrl: config.dataBaseVersionURL }),
    tagTypes: ['DataBase'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        dataBaseVersion: builder.query({
            query: () => {
                return `/BaseDatos_Version?key=${config.keyAPICrufdek}`
            }
        }),
    })
})

export const { 
    useDataBaseVersionQuery,
} = dataBaseApi