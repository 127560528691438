import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { config } from "../../config/config"
import { EkQuery } from "../query"

const TABLA_ROLES = 'EurekaCrRol'
const TABLA_ROLES_USUARIOS = 'EurekaCrRolUsuario'

export const rolApi = createApi({
    reducerPath: 'rolApi',
    baseQuery: fetchBaseQuery({ baseUrl: config.crufdekURLV1 }),
    tagTypes: ['Roles'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        obtenerRoles: builder.query({
            query: (data) => {
                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${data.token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: new EkQuery(TABLA_ROLES).get()
                }
            }
        }),
        crearRoles: builder.mutation({
            query: (data) => {
                let body = null
                if (data.id !== null) {
                    body = new EkQuery(TABLA_ROLES)
                                .where("id", data.id)
                                .update(data.body)
                }
                else {
                    body = new EkQuery(TABLA_ROLES)
                                .create(data.body)
                }

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${data.token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
        crearRolUsuario: builder.mutation({
            query: (data) => {
                let body = null
                if (data?.id) {
                    body = new EkQuery(TABLA_ROLES_USUARIOS)
                                .where("id", data.id)
                                .update(data.body)
                }
                else {
                    body = new EkQuery(TABLA_ROLES_USUARIOS)
                                .create(data.body)
                }

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${data.token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        })
    })
})

export const {
    useObtenerRolesQuery,
    useCrearRolesMutation,
    useCrearRolUsuarioMutation,
} = rolApi