import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { config } from "../../config/config"
import { EkQuery } from "../query"
import { token } from "account-react-eurekasigma"

const TABLA_PRODUCTOS = 'EurekaStProducto'
const TABLA_PRODUCTOS_PRECIOS = 'EurekaStProductosPrecio'
const TABLA_PRODUCTOS_FOTOS = 'EurekaStProductosFoto'
const TABLA_MULTIMEDIAS = 'EurekaCrMultimedia'

let queryDataProducto = null

export const productoApi = createApi({
    reducerPath: 'productoApi',
    baseQuery: fetchBaseQuery({ baseUrl: config.crufdekURL }),
    tagTypes: ['Productos'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        obtenerProductos: builder.query({
            /**
             * @param {{ col: string, val: string, mostrarArchivados: boolean }} data 
             */
            query: (data) => {
                let body = null
                let mostrarArchivados = data.mostrarArchivados !== undefined ? data.mostrarArchivados : true
                queryDataProducto = data

                if(mostrarArchivados) {
                    body = new EkQuery(TABLA_PRODUCTOS)
                                .with('eureka_st_productos_precios')
                                .with('eureka_st_productos_fotos.eureka_cr_multimedia')
                }
                else {
                    body = new EkQuery(TABLA_PRODUCTOS)
                                .where('estatus', '!=', 'archivado')
                                .with('eureka_st_productos_precios')
                                .with('eureka_st_productos_fotos.eureka_cr_multimedia')
                }

                if(data.tipoProducto !== undefined && data.tipoProducto !== null) {
                    body.where('ecommerce', '=', data.tipoProducto)
                }
                // else no filtrar los productos

                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body.get()
                }
            },
            transformResponse: (response) => {
                const { col, val } = queryDataProducto
                
                let productos =  response.filter((producto) => {
                    if(col && val) {
                        return producto[col].toLowerCase().includes(val.toLowerCase())
                    }
                    else {
                        return true
                    }
                })
                return productos
            },
        }),
        obtenerProductosCompras: builder.query({
            /**
             * @param {{ mostrarArchivados: boolean, tipoProducto: string, col: string, val: string }} data 
             */
            query: (data) => {
                queryDataProducto = data
                let body = new EkQuery(TABLA_PRODUCTOS)
                                .where('estatus', '!=', 'archivado')
                                .with('eureka_st_productos_precios')
                                .with('eureka_st_productos_fotos.eureka_cr_multimedia')

                if(data.tipoProducto !== undefined && data.tipoProducto !== null) {
                    body.where('ecommerce', '=', data.tipoProducto)
                }
                else {
                    body.where('ecommerce', '=', 'ninguno')
                }

                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body.get()
                }
            },
            transformResponse: (productos) => {
                const {col, val} = queryDataProducto

                productos = productos.filter((producto) => {
                    let objPrecio = producto.eureka_st_productos_precios
                    let buscado

                    if(col && val) buscado = producto[col].toLowerCase().includes(val.toLowerCase())
                    else buscado = true

                    if(objPrecio.length > 0) {
                        objPrecio = objPrecio[0]
                        return buscado && objPrecio.numero > 0 && objPrecio.precio > 0
                    }
                    else {
                        return false
                    }
                })
                return productos
            },
        }),
        crearProducto: builder.mutation({
            /**
             * 
             * @param {{ id:number, body:object }} data 
             * @returns 
             */
            query: (data) => {
                let body = null
                if (data.id !== null && data.id !== undefined) {
                    body = new EkQuery(TABLA_PRODUCTOS)
                                .where("id", data.id)
                                .update(data.body)
                }
                else body = new EkQuery(TABLA_PRODUCTOS).create(data.body)

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
        crearProductoPrecio: builder.mutation({
            /**
             * 
             * @param {{ id:number, productoID:number, body:object }} data 
             * @returns 
             */
            query: (data) => {
                let body = null
                if(data?.productoID) {
                    body = new EkQuery(TABLA_PRODUCTOS_PRECIOS)
                                .where("producto", data.productoID)
                                .update(data.body)
                }
                else if(data?.id) {
                    body = new EkQuery(TABLA_PRODUCTOS_PRECIOS)
                                .where("id", data.id)
                                .update(data.body)
                }
                else body = new EkQuery(TABLA_PRODUCTOS_PRECIOS).create(data.body)

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
        actualizarFoto: builder.mutation({
            query: (data) => {
                let body = null
                if(data.id)
                    body = new EkQuery(TABLA_MULTIMEDIAS)
                                .where('id', data.id)
                                .uploadFile(data.file, data.carpeta, data.nombre)
                else
                    body = new EkQuery(TABLA_MULTIMEDIAS).uploadFile(data.file, data.carpeta, data.nombre)

                let form = new FormData()
                Object.entries(body).forEach(([key, value]) => {
                    form.append(key, value)
                })

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    headers: { 'Authorization' : `Bearer ${token()}` },
                    enctype: "multipart/form-data",
                    body: form
                }
            },
        }),
        relacionarFoto: builder.mutation({
            query: (data) => {
                let body = new EkQuery(TABLA_PRODUCTOS_FOTOS)
                                .create({
                                    foto: data.fotoID,
                                    producto: data.productoID
                                })
                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        })
    })
})

export const {
    useObtenerProductosQuery,
    useObtenerProductosComprasQuery,
    useCrearProductoMutation,
    useCrearProductoPrecioMutation,
    useActualizarFotoMutation,
    useRelacionarFotoMutation,
} = productoApi