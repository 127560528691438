import { Formulario, Input } from "eureka-design"
import { useCrearPorcentajeComisionMutation, useObtenerPorcentajesComisionesQuery } from "../../js/services/comision"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setHandler } from "../../js/feactures/HandlerQuerySlice"
import { AdminComision } from "../../js/AdministradorComision"

export const NOM_FORM_PORCENTAJE_COMISION = 'PorcentajeComision'

export default function FormPorcentajeComision({ ShowAlert }) {
    /** ESTADOS GLOBALES */
    const dispatch = useDispatch()
    const formPorcentajeComision = useSelector(state => state.handlerQuerySlice[NOM_FORM_PORCENTAJE_COMISION])
    
    /** ESTADOS */
    const [comisionID, fijaComisionID] = useState(formPorcentajeComision.id)
    const [nivel, fijaNivel] = useState(0)
    
    /** HOOKS */
    const { 
        data: comisiones, 
        isLoading: cargandoComisiones, 
        refetch: actualizarComisiones 
    } = useObtenerPorcentajesComisionesQuery({ tipo_usuario: formPorcentajeComision.tipo_usuario })
    const [crearPorcentajeComision, {
        isLoading,
        isError,
        isSuccess
    }] = useCrearPorcentajeComisionMutation()

    // console.log(formPorcentajeComision)

    useEffect(() => {
        if(formPorcentajeComision.tipo_comision) {
            fijaNivel(parseInt(formPorcentajeComision.tipo_comision))
        }
        // else se esta creando el registro
    }, [formPorcentajeComision, fijaNivel])

    async function handlerComision(nom, val, id = null) {
        return await crearPorcentajeComision({
            id: comisionID ? comisionID : id,
            body: { [nom]: val }
        })
        .unwrap()
        .then((res) => {
            actualizarComisiones()
            if(res?.mensaje?.id) {
                fijaComisionID(res.mensaje.id)
                handlerFormData('id', res.mensaje.id)
                return res.mensaje.id
            }
            else return null
        })
    }

    const guardarDetalles = (id = null) => {
        if(id) {
            let nivelSig = AdminComision.dameSigNivel(comisiones)
            handlerFormData('tipo_usuario', formPorcentajeComision.tipo_usuario)
            handlerComision('tipo_usuario', formPorcentajeComision.tipo_usuario, id)
            handlerFormData('nombre_comision', `Nivel ${nivelSig}`)
            handlerComision('nombre_comision', `Nivel ${nivelSig}`, id)
            handlerFormData('tipo_comision', nivelSig)
            handlerComision('tipo_comision', nivelSig, id)
            fijaNivel(nivelSig)
        }
        // else ya existe el registro
    }

    const handlerInput = async (val, nom) => {
        let id = null
        if(nom === 'porcentaje') {
            if(AdminComision.porcentajeComisionValido({ id: comisionID, porcentaje: val }, comisiones, ShowAlert)) {
                handlerFormData(nom, val)
                id = await handlerComision(nom, val)
                guardarDetalles(id)
            }
            // else porcentaje no valido
        }
        else {
            handlerFormData(nom, val)
            id = await handlerComision(nom, val)
            guardarDetalles(id)
        }
    }

    const handlerFormData = (nom, val) => {
        dispatch(setHandler({ 
            state: NOM_FORM_PORCENTAJE_COMISION, 
            content: { 
                id: comisionID,
                [nom]: val
            } 
        }))
    }

    if(cargandoComisiones) {
        return
        // return <p>Cargando, por favor espere...</p>
        // return (
        //     <CargandoPagina
        //         mensaje="Cargando, por favor espere..."
        //         colorPrincipal={config.colorPrincipal}
        //     />
        // )
    }
    else {
        let tituloForm = ''
        if((nivel > 0) && formPorcentajeComision.tipo_usuario) {
            let tipoComision = null
            if(formPorcentajeComision.tipo_usuario === AdminComision.AFILIADO) {
                tipoComision = 'Inscripción'
            }
            else if(formPorcentajeComision.tipo_usuario === AdminComision.COMPRA) {
                tipoComision = 'Compras minímas generales'
            }
            else {
                tipoComision = 'Compras máximas generales'
            }
            tituloForm = `Porcentaje comisión nivel ${nivel} ${tipoComision ? (' - '+tipoComision)  : ''}`
        }
        else {
            tituloForm = 'Porcentaje Comisión'
        }

        return (
            <Formulario
                titulo={tituloForm}
                isLoading={[isLoading]}
                isError={[isError]}
                isSuccess={[isSuccess]}
            >
                <Input
                    type="number"
                    nombre="fijo"
                    placeholder={
                        formPorcentajeComision.tipo_usuario === AdminComision.EXCEDENTE ? 
                            'Cuota máxima' : 
                            'Cuota minima'
                    }
                    value={formPorcentajeComision.fijo}
                    isLoading={isLoading}
                    changeFunction={handlerInput}
                    min="0"
                    max="99999999"
                />
                <Input 
                    type="number"
                    nombre="porcentaje"
                    placeholder={'Porcentaje'}
                    value={formPorcentajeComision.porcentaje}
                    isLoading={isLoading}
                    changeFunction={handlerInput}
                    min="0"
                    max="100"
                />
            </Formulario>
        )
    }
}
