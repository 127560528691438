import { useDispatch, useSelector } from "react-redux"
import { Formulario, Input, ImageInput, Select } from "eureka-design"
import { 
    useActualizarFotoMutation, 
    useCrearProductoMutation, 
    useCrearProductoPrecioMutation, 
    useRelacionarFotoMutation 
} from "../../js/services/producto"
import { setHandler } from "../../js/feactures/HandlerQuerySlice"
import { useState } from "react"

export const NOM_FORM_PRODUCTO = 'formProductos'
const inputs = [
    { type: 'text', nom: 'nombre', placeholder: 'Nombre'},
    { type: 'text', nom: 'codigo', placeholder: 'Código'},
    { type: 'textarea', nom: 'descripcion', placeholder: 'Descripción'},
]
const inputsPrecio = [
    { nom: 'costo', placeholder: 'Valor comisionable'},
    { nom: 'precio', placeholder: 'Precio'},
    { nom: 'numero', placeholder: 'Existencia'},
]

export default function FormProducto({ ShowAlert }) {
    const categorias = [
        { text: 'Afiliado', value: 'Afiliado', valueDB: 'offline' },
        { text: 'Compra', value: 'Compra', valueDB: 'online' }
    ]

    /** STATES */
    const dispatch = useDispatch()
    const formProductos = useSelector(state => state.handlerQuerySlice[NOM_FORM_PRODUCTO])
    const [productoID, fijaProductoID] = useState(formProductos.id)
    const [precioID, fijaPrecioID] = useState(formProductos.precioID)
    const [fotoID, fijaFotoID] = useState(formProductos.fotoID)
    
    /** HOOK */
    const [
        crearProductoMutation, 
        { 
            isLoading: actualizandoEntrada, 
            isError: entradaErronea, 
            isSuccess: entradaActualizada
        }
    ] = useCrearProductoMutation()
    const [
        crearProductoPrecioMutation, 
        { 
            isLoading: actualizandoEntradaPrecio, 
            isError: entradaPrecioErronea, 
            isSuccess: entradaPrecioActualizada
        }
    ] = useCrearProductoPrecioMutation()
    const [actualizarFoto, { isLoading: actualizandoFoto }] = useActualizarFotoMutation()
    const [relacionarFoto] = useRelacionarFotoMutation()

    async function handlerProducto(nom, val) {
        return await crearProductoMutation({
                id: productoID,
                body: { [nom]: val, estatus: 'activo' }
            })
            .unwrap()
            .then((response) => {
                if (response?.mensaje?.id) {
                    let id = response.mensaje.id
                    fijaProductoID(id)
                    handlerFormData('id', id)
                    return id
                }
                // else se actualizo el registro producto
            })
            .catch((error) => { if(error?.status === 401) window.location.reload() })
    }

    async function handlerPrecio(nom, val) {
        let id = null
        if(productoID === null || productoID === undefined) {
            id = await handlerProducto('nombre', '')
        }
        else id = productoID

        return await crearProductoPrecioMutation({
                id: precioID,
                body: { [nom]: val, producto: id }
            })
            .unwrap()
            .then((response) => {
                if (response?.mensaje?.id) {
                    let id = response.mensaje.id
                    fijaPrecioID(id)
                    handlerFormData('precioID', id)
                    return id
                }
                // else se actualizo el registro precio
            })
            .catch((error) => { if(error?.status === 401) window.location.reload() })
    }

    const handlerFormData = (key, val) => {
        dispatch(setHandler({ 
            state: NOM_FORM_PRODUCTO, 
            content: { 
                id: productoID,
                precioID: precioID, 
                fotoID: fotoID, 
                [key]: val 
            } 
        }))
    }

    const handlerInput = (val, nom) => {
        handlerFormData(nom, val)
        handlerProducto(nom, val)
    }

    const handlerInputPrecio = (val, nom) => {
        if(nom === 'costo') {
            handlerFormData(nom, val)
            handlerProducto(nom, val)
            // if(parseFloat(val) < parseFloat(formProductos.precio)) {
            //     handlerFormData(nom, val)
            //     handlerProducto(nom, val)
            // }
            // else {
            //     ShowAlert({
            //         icono: 'error',
            //         titulo: 'Costo no válido',
            //         time: 5,
            //         mensaje: 'El costo del producto no puede ser mayor que el precio'
            //     })
            // }
        }
        else {
            if(nom === 'numero') {
                val = parseInt(val)
                val = Number.isInteger(val) ? val : 0;
            }
            // else no adaptar el valor

            handlerFormData(nom, val)
            handlerPrecio(nom, val)
        }
    }

    async function handlerFoto(e) {
        let producto = null
        if(productoID === null || productoID === undefined) producto = await handlerProducto('nombre', '')
        else producto = productoID

        return await actualizarFoto({
            id: fotoID,
            file: e.target.files[0],
            carpeta: 'camvi432_productos',
            nombre: 'nombre',
        })
        .unwrap()
        .then((response) => {
            handlerFormData('preview', e.target.files[0])
            if (response?.mensaje?.id) {
                let foto = response.mensaje.id
                fijaFotoID(foto)
                handlerFormData('fotoID', foto)
                relacionarFoto({ fotoID: foto, productoID: producto })
                return foto
            }
            // else se actualizo el registro
        })
        .catch((error) => { if(error?.status === 401) window.location.reload() })
    }

    const handlerSelect = (e, nom) => {
        handlerFormData(nom, e.valueDB)
        handlerProducto(nom, e.valueDB)
    }

    return (
        <Formulario 
            titulo="Producto"
            isLoading={[actualizandoEntrada, actualizandoEntradaPrecio, actualizandoFoto]}
            isError={[entradaErronea, entradaPrecioErronea]}
            isSuccess={[entradaActualizada, entradaPrecioActualizada]}
        >
            <ImageInput 
                id={'fotoProducto'}
                preview={formProductos?.preview}
                avatar={true}
                changeFunction={handlerFoto}
                isLoading={actualizandoFoto}
                width={200}
                height={200}
            />
            {
                inputs.map((input, index) => {
                    return <Input
                                type={input.type}
                                key={index}
                                changeFunction={handlerInput}
                                value={formProductos[input.nom]}
                                min={5}
                                max={50}
                                isLoading={actualizandoEntrada}
                                nombre={input.nom}
                                placeholder={input.placeholder}
                            />
                })
            }
            <Select
                nombre="ecommerce"
                placeholder="Categoría"
                value={formProductos?.ecommerce ? (formProductos.ecommerce === 'offline' ? 'Afiliado' : 'Compra') : 'Afiliado o Compra'}
                options={categorias}
                isLoading={actualizandoEntrada}
                changeFunction={handlerSelect}
            />
            {
                inputsPrecio.map((input, index) => {
                    return  <Input
                                key={index}
                                type="text"
                                changeFunction={handlerInputPrecio}
                                value={formProductos[input.nom]}
                                isLoading={actualizandoEntradaPrecio}
                                nombre={input.nom}
                                placeholder={input.placeholder}
                            />
                })
            }
        </Formulario>
    )
}