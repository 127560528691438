import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../config/config";
import { EkQuery } from "../query";
import { AdminComision } from "../AdministradorComision";
import { token } from "account-react-eurekasigma"

const TABLA_COMISION = 'EurekaStTransaccionesComisiones'
const TABLA_PORCENTAJE_COMISIONES = 'EurekaPaComisiones'
const TABLA_TRANSACCION_DETALLE = 'EurekaStTransaccionesDetalle'

export const comisionApi = createApi({
    reducerPath: 'comisionApi',
    baseQuery: fetchBaseQuery({ baseUrl: config.crufdekURL }),
    tagTypes: ['Comisiones'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        crearComision: builder.mutation({
            /**
             * 
             * @param {{ id: number, body: object }} data 
             * @returns 
             */
            query: (data) => {
                let body = null
                if(data.id) {
                    body = new EkQuery(TABLA_COMISION)
                                .where('id', data.id)
                                .update(data.body)
                }
                else {
                    body = new EkQuery(TABLA_COMISION)
                                .create(data.body)
                }

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            },
        }),
        obtenerComisiones: builder.query({
            /**
             * 
             * @param {{ id: number }} data 
             * @returns 
             */
            query: (data) => {
                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: new EkQuery(TABLA_COMISION)
                            .where('comisionista', data.id)
                            .with('eureka_st_transaccion')
                            .get()
                }
            },
        }),
        obtenerPorcentajesComisiones: builder.query({
            /**
             * 
             * @param {{ tipo_usuario: string }} data 
             * @returns 
             */
            query: (data) => {
                let body = null
                if(data.tipo_usuario) {
                    body = new EkQuery(TABLA_PORCENTAJE_COMISIONES)
                                .where('tipo_usuario', '=', data.tipo_usuario)
                                .get()
                }
                else {
                    body = new EkQuery(TABLA_PORCENTAJE_COMISIONES).get()
                }

                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            },
        }),
        obtenerNiveles: builder.mutation({
            /**
             * @param {{ tipo_usuario: string }} data  
             */
            query: (data) => {
                let body = new EkQuery(TABLA_PORCENTAJE_COMISIONES)
                if(data?.tipo_usuario) {
                    if(data.tipo_usuario === AdminComision.AFILIADO) {
                        body = body
                                .where('tipo_usuario', '=', AdminComision.AFILIADO)
                                .get()
                    }
                    else {
                        body = body
                                .where('tipo_usuario', '!=', AdminComision.AFILIADO)
                                .get()
                    }
                }
                else {
                    body = body.get()
                }

                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            },
            transformResponse: (niveles) => {
                let afiliaciones = niveles.filter((nivel) => nivel.tipo_usuario === AdminComision.AFILIADO)
                afiliaciones = afiliaciones.sort((afilicionSig, afilicionAnt) => {
                    return parseInt(afilicionSig.tipo_comision) - parseInt(afilicionAnt.tipo_comision) 
                })

                let compras = niveles.filter((nivel) => nivel.tipo_usuario === AdminComision.COMPRA)
                compras = compras.sort((compraSig, compraAnt) => {
                    return parseInt(compraSig.tipo_comision) - parseInt(compraAnt.tipo_comision) 
                })

                let excedentes = niveles.filter((nivel) => nivel.tipo_usuario === AdminComision.EXCEDENTE)
                excedentes = excedentes.sort((excedenteSig, excedenteAnt) => {
                    return parseInt(excedenteSig.tipo_comision) - parseInt(excedenteAnt.tipo_comision) 
                })
                niveles = {}

                niveles[AdminComision.AFILIADO] = afiliaciones
                niveles[AdminComision.COMPRA] = compras
                niveles[AdminComision.EXCEDENTE] = excedentes

                return niveles
            }
        }),
        crearPorcentajeComision: builder.mutation({
            query: (data) => {
                let body = null
                if(data.id) {
                    body = new EkQuery(TABLA_PORCENTAJE_COMISIONES)
                                .where('id', data.id)
                                .update(data.body)
                }
                else {
                    body = new EkQuery(TABLA_PORCENTAJE_COMISIONES)
                                .create(data.body)
                }

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            },
        }),
        obtenerReportes: builder.query({
            query: (data) => {
                let body = null
                if(data.distribuidorID) {
                    body = new EkQuery(TABLA_COMISION)
                                .where('comisionista', '=', data.distribuidorID)
                                .with('eureka_ac_comisionista')
                                .with('eureka_st_transaccion.eureka_st_transacciones_detalles')
                                .with('eureka_st_transaccion.eureka_ac_usuario')
                }
                else {
                    body = new EkQuery(TABLA_TRANSACCION_DETALLE)
                                .where('nombre', '=', 'Fecha fin de reporte')
                                .with('eureka_st_transaccione.eureka_st_comisiones.eureka_st_transaccion.eureka_ac_usuario')
                                .with('eureka_st_transaccione.eureka_st_comisiones.eureka_ac_comisionista')
                }

                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body.get()
                }
            }
        }),
        obtenerReportesHechos: builder.query({
            query: (data) => {
                let body = null
                if(data.fechaPatron) {
                    body = new EkQuery(TABLA_TRANSACCION_DETALLE)
                            .where('nombre', '=', 'Fecha fin de reporte')
                            .where('texto', '=', `%${data.fechaPatron}%`)
                }
                else {
                    body = new EkQuery(TABLA_TRANSACCION_DETALLE)
                            .where('nombre', '=', 'Fecha fin de reporte')
                }

                return {
                    url: `/READ?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body.get()
                }
            },
        }),
    })
})

export const {
    useCrearComisionMutation,
    useObtenerComisionesQuery,
    useObtenerPorcentajesComisionesQuery,
    useObtenerNivelesMutation,
    useCrearPorcentajeComisionMutation,
    useObtenerReportesQuery,
    useObtenerReportesHechosQuery,
} = comisionApi