import { FolioSection } from "eureka-design"
import { capitalize, numberFormat } from "./util"

const PERIODO_TERMINADO = 'pagar'
const LIQUIDADO = 'liquidado'
const REGISTRO_EXCEL = 'registro_excel'

export class AdminComision {
    static TOTAL_NIVELES = 10
    static AFILIADO = 'Afiliado'
    static COMPRA = 'Compra'
    static EXCEDENTE = 'Excedente'

    calcularComisionTotal(comisiones = [], mostrarFechas = true) {
        let infoComision = 'Sin comision/es'
        let total = 0
        let fechaInicial
        let fechaFinal
        let fecha

        if(comisiones.length === 0) {
            if(mostrarFechas)
                return infoComision
            else
                return '$0.00'
                
        }
        else {
            infoComision = ''
            total = comisiones[0].comision

            if(mostrarFechas) {
                fechaInicial = new Date(comisiones[0].eureka_st_transaccion.fecha)
                fechaFinal = new Date(comisiones[0].eureka_st_transaccion.fecha)

                for(let i = 1; i < comisiones.length; i++) {
                    fecha = new Date(comisiones[i].eureka_st_transaccion.fecha)

                    total += comisiones[i].comision
                    if(fecha.getTime() > fechaFinal.getTime()) {
                        fechaFinal = fecha
                    }
                    else if(fecha.getTime() < fechaInicial.getTime()) {
                        fechaInicial = fecha
                    }
                    // else es una fecha intermedia
                }

                if(fechaInicial.getTime() === fechaFinal.getTime()) {
                    return `$${Number(total).toFixed(2)} ${fechaInicial.getDate()}/${fechaInicial.getMonth()}/${fechaInicial.getFullYear()}`
                }
                else {
                    return `$${Number(total).toFixed(2)} ${fechaInicial.getDate()}/${fechaInicial.getMonth()}/${fechaInicial.getFullYear()} al ${fechaFinal.getDate()}/${fechaFinal.getMonth()}/${fechaFinal.getFullYear()}`
                }
            }
            else {
                for(let i = 1; i < comisiones.length; i++) {
                    total += comisiones[i].comision
                }
                return `$${Number(total).toFixed(2)}`
            }
        }
    }

    static obtenerPadre(usuario = {}) {
        if(usuario) {
            let contactosFiltrados = usuario.eureka_bs_contactos.filter(contacto => {
                return contacto.relacion === 'padre'
            })

            if(contactosFiltrados.length === 0) {
                return null
            }
            else {
                return contactosFiltrados[0]
            }
        }
        else {
            return null
        }
    }

    static tengoVentaRealizada(transacciones = [], fechaInicio = null, fechaFin = null) {
        if(transacciones.length === 0) {
            return false
        }
        else if(fechaInicio) {
            return (transacciones.filter(transaccion => {
                let fechaVenta = new Date(transaccion.fecha)
                return (transaccion.cxp_cxc === PERIODO_TERMINADO || transaccion.cxp_cxc === LIQUIDADO || transaccion.tipo === REGISTRO_EXCEL) &&
                       (fechaVenta >= fechaInicio && fechaVenta <= fechaFin)
            })).length > 0
        }
        else {
            return (transacciones.filter(transaccion => {
                return (transaccion.cxp_cxc === PERIODO_TERMINADO || transaccion.cxp_cxc === LIQUIDADO || transaccion.tipo === REGISTRO_EXCEL)
            })).length > 0
        }
    }

    static calcularComision(nivel = null, venta = null, comisionista = null, esExcedente = false) {       
        let totalComisiones = 0

        if(esExcedente) {
            totalComisiones += (parseFloat(venta.total) * parseFloat(nivel.porcentaje) / 100)
        }
        else {
            venta.eureka_st_transacciones_conceptos.map(concepto => {
                totalComisiones += (parseFloat(concepto.eureka_st_producto.costo) * parseFloat(nivel.porcentaje) / 100) * parseInt(concepto.cantidad)
                return true
            })
        }

        return {
            nombre_comision: venta.tipo,
            porcentaje: nivel.porcentaje,
            comision: totalComisiones,
            comisionista: comisionista.id,
            transaccion: venta.id,
            nombre_comisionista: comisionista.nombre
        }
    }

    static creaComponenteInfo(comision = null, indice = 1) {
        return (
            <div key={indice} style={{display: 'grid', gap: '5px'}}>
                <FolioSection titulo={"Comision"} subTile={capitalize(comision?.eureka_ac_comisionista?.nombre ?? '')}>
                    <span>{numberFormat(comision?.comision ?? 0, 2, '.', ',', '$')}</span>
                </FolioSection>
                <FolioSection titulo={"Tipo de comisión"}>
                    <span>{capitalize(comision.nombre_comision)}</span>
                </FolioSection>
            </div>
        )
    }

    static existeCalculoComisiones(comisiones = [], ventaID = null) {
        let filtros = comisiones.filter((comision, i) => {
            let keys = Object.keys(comision)
            return parseInt(keys[0]) === ventaID
        })

        return filtros.length > 0
    }

    static obtenerComisionCalculada(comisiones = [], ventaID = null) {
        let filtros = comisiones.filter((comision, i) => {
            let keys = Object.keys(comision)
            return parseInt(keys[0]) === ventaID
        })

        if(filtros.length > 0)
            return filtros[0][ventaID]
        else 
            return null
    }

    static dameTotalVentas(ventas) {
        let totalVentas = 0
        ventas.map(venta => {
            totalVentas += parseFloat(venta.total)
            return true
        })
        return totalVentas
    }

    static async obtenerCalculoComisiones(movimiento = null, config = null) {
        let comisiones = []
        let ventasExcedentes = []
        let nivelExcedente = config.primerNivelExcedente
        let hijo = movimiento?.eureka_ac_usuario
        let padre = this.obtenerPadre(movimiento?.eureka_ac_usuario)

        for(let i = 0; i < config.niveles.length; i++) {
            let nivel = config.niveles[i]

            if(!padre) break
            else {
                hijo = await config.callbackInfoDistribuidor({ token: config.token, id: padre.contacto })
                    .unwrap()
                    .then((distribuidor) => {
                        let ventas = distribuidor.eureka_st_transacciones
                        let totalVentas = 0
                        let comision = null

                        if(this.tengoVentaRealizada(ventas, config.fechaInicial, config.fechaFinal)) {
                            ventas.map(venta => {
                                if(venta.cxp_cxc === 'cobrar') {
                                    totalVentas += 0
                                }
                                else {
                                    totalVentas += parseFloat(venta.total)
                                }
                                return true
                            })

                            if(totalVentas >= parseFloat(nivel.fijo)) {
                                comision = this.calcularComision(nivel, movimiento, distribuidor)
                            }
                            else {
                                comision = this.calcularComision(nivel, movimiento, config.usuarioCamvi)
                            }

                            if(nivelExcedente) {
                                if(totalVentas > parseFloat(nivelExcedente.fijo)) {
                                    ventasExcedentes.push({
                                        tipo: 'excedente',
                                        fecha: (new Date()).toJSON(),
                                        cxp_cxc: 'pagar',
                                        vendedor: hijo.id,
                                        total: parseFloat(totalVentas) - parseFloat(nivelExcedente.fijo)
                                    })
                                }
                                // else no supero el excedente
                            }
                            // else no existen ningún nivel para excedentes
                            comisiones.push(comision)
                        }
                        else i -= 1
                        return distribuidor
                    })
                padre = this.obtenerPadre(hijo)
            }
        }
        return {
            comisiones: comisiones,
            ventasExcedentes: ventasExcedentes
        }
    }

    static async obtenerCalculoComisionesExcedentes(movimiento = null, config = null) {
        let comisiones = []
        let hijo = config.vendedor
        let padre = this.obtenerPadre(hijo)

        for(let i = 0; i < config.niveles.length; i++) {
            let nivel = config.niveles[i]

            if(!padre) break
            else {
                hijo = await config.callbackInfoDistribuidor({ token: config.token, id: padre.contacto })
                    .unwrap()
                    .then((distribuidor) => {
                        let ventas = distribuidor.eureka_st_transacciones
                        let totalVentas = 0
                        let comision = null

                        if(i === 0) {
                            comision = this.calcularComision(nivel, movimiento, distribuidor, true)
                            comisiones.push(comision)
                        }
                        else {
                            if(this.tengoVentaRealizada(ventas, config.fechaInicial, config.fechaFinal)) {
                                ventas.map(venta => {
                                    if(venta.cxp_cxc === 'cobrar') totalVentas += 0
                                    else totalVentas += parseFloat(venta.total)
                                    return true
                                })

                                if(totalVentas > parseFloat(nivel.fijo))
                                    comision = this.calcularComision(nivel, movimiento, distribuidor, true)
                                else
                                    comision = this.calcularComision(nivel, movimiento, config.usuarioCamvi, true)
                                comisiones.push(comision)
                            }
                            else i -= 1
                        }
                        return distribuidor
                    })
                padre = this.obtenerPadre(hijo)
            }
        }
        return comisiones
    }

    static porcentajeComisionValido(porcentajeComision = {}, porcentajesCsmisiones = [], callbackMensaje = () => {}) {
        let sumaPorcentaje = 0
        let porcentaje = parseInt(porcentajeComision.porcentaje)

        porcentajesCsmisiones.map((comision) => {
            if(comision.id !== porcentajeComision.id)
                sumaPorcentaje += parseInt(comision.porcentaje)
            return true
        })

        sumaPorcentaje += Number.isInteger(porcentaje) ? porcentaje :  0
        if(sumaPorcentaje === 100) {
            callbackMensaje({
                icono: 'info',
                titulo: 'Se alcanzo el máximo',
                mensaje: 'La suma de los porcentajes de comisiones alcanzo el 100%, el vendendedor se quedara sin ganancia'
            })
            return true
        }
        else if(sumaPorcentaje > 100) {
            callbackMensaje({
                icono: 'info',
                titulo: 'Se supero el máximo',
                mensaje: 'La suma de los porcentajes de comisiones supera el 100%, no se a guardado el cambio'
            })
            return false
        }
        else {
            return true
        }
    }

    static dameSigNivel(comisiones = []) {
        let niveles = comisiones.map(comision => parseInt(comision.tipo_comision)).sort()
        let sigNivel = 0
        let  i

        for(i = 0; i < niveles.length; i++) {
            if(niveles[i] === (i+1)) continue
            else {
                sigNivel = (i+1)
                break
            }
        }

        if(i === niveles.length) {
            return comisiones.length + 1
        }
        else {
            return sigNivel
        }
    }

}