import { Navegador, Theme } from "eureka-design";
// import Movimientos from "../ventas/Movimientos";
import Distribuidores from "../distribuidores/Distribuidores";
import Distribuidor from "../distribuidores/Distribuidor";
import Productos from "../productos/Productos";
import Comisiones from "../comisiones/Comisiones";
import FormDistribuidor from "../distribuidores/FormDistribuidor";
import FormProducto from "../productos/FormProducto";
import FormMovimiento from "../ventas/FormMovimiento";
import DetalleComisiones from "../comisiones/DetalleComisiones";
import Perfil from "../perfil/Perfil";
import PorcentajeComisionInscripcion from "../porcentajes_comisiones/PorcentajeComisionInscripcion";
import { obtenerFoto, obtenerRol } from "../../js/util";
import FormPorcentajeComision from "../porcentajes_comisiones/FormPorcentajeComision";
import DetalleMovimiento from "../ventas/DetalleMovimiento";
import { config } from "../../config/config";
import EditarPerfil from "../perfil/EditarPerfil";
import PorcentajesComisionGeneral from "../porcentajes_comisiones/PorcentajeComisionGeneral";
import PorcentajeComisionGeneralMax from "../porcentajes_comisiones/PorcentajeComisionGeneralMax";
import { usuario as infoUsuario } from 'account-react-eurekasigma'
import InformacionFiscal from "../perfil/InformacionFiscal";
import { useEffect, useState } from "react";
import { adaptarCatalogoSAT, CATALOGO_REGIMENES_FISCALES, obtenerCatalogoSAT } from "../../js/factura";
import Ventas from "../ventas/Ventas";

const Index = () => {
    const usuario = infoUsuario()

    const [cargandoCatalogos, cambiarEstadoCargandoCatalogo] = useState(true)
    const [regimenesSociales, fijaRegimenesSociales] = useState([])

    useEffect(() => {
        const cargarCatalogos = async () => {
            fijaRegimenesSociales(adaptarCatalogoSAT(await obtenerCatalogoSAT(CATALOGO_REGIMENES_FISCALES), true))
            cambiarEstadoCargandoCatalogo(false)
        }

        if(cargandoCatalogos) cargarCatalogos()
        // else catalogos del SAT cargados
    }, [cargandoCatalogos, cambiarEstadoCargandoCatalogo])

    let componentes = {
        "Movimientos": {
            opcion: {
                texto: "Ventas",
                icono: "fa-file-invoice-dollar",
            },
            pantallas: {
                ventas: {
                    props: {},
                    tab: "Ventas",
                    titulo: "Lista de Ventas",
                    componente: Ventas
                },
                // movimientos: {
                //     props: { usuario: usuario },
                //     tab: "Movimientos",
                //     titulo: "Movimientos",
                //     componente: Movimientos,
                // },
                comisiones: {
                    props: { usuario: usuario },
                    tab: "Reportes",
                    titulo: "Reportes",
                    componente: Comisiones,
                },
                formMovimiento: {
                    props: { usuario: usuario },
                    titulo: 'Movimiento',
                    componente: FormMovimiento,
                },
                detalle_comisiones: {
                    props: { usuario: usuario },
                    titulo: 'Comisiones',
                    componente: DetalleComisiones,
                },
                detalleMovimiento: {
                    props: { usuario: usuario },
                    titulo: 'Detalle movimiento',
                    componente: DetalleMovimiento,
                }
            },
        },
        "Productos": {
            opcion: {
                texto: "Productos",
                icono: "fa-boxes",
            },
            pantallas: {
                productos: {
                    props: { usuario },
                    titulo: "Productos",
                    componente: Productos,
                },
                formProducto: obtenerRol(usuario) === 'administrador' ?  
                    {
                        props: {},
                        titulo: "Producto",
                        componente: FormProducto,
                    } : {}
            },
        },
        "Perfil": {
            pantallas: {
                miperfil: {
                    props: { completarPerfil: false, regimenesSociales },
                    titulo: "Mi perfil",
                    componente: Perfil,
                },
                editarperfil: {
                    props: {},
                    titulo: "Editar mi perfil",
                    componente: EditarPerfil,
                },
                informacion_fiscal: {
                    props: { regimenesSociales },
                    titulo: "Información Fiscal",
                    componente: InformacionFiscal,
                },
            }
        },
    }
    let componentesUsuario = {}

    if (obtenerRol(usuario) === 'administrador') {
        componentesUsuario = {
            "Distribuidores": {
                opcion: {
                    texto: "Distribuidores",
                    icono: "fa-users",
                },
                pantallas: {
                    distribuidores: {
                        props: {},
                        titulo: "Distribuidores",
                        componente: Distribuidores,
                    },
                    distribuidor: {
                        props: {},
                        titulo: "Distribuidor",
                        componente: Distribuidor,
                    },
                    formDistribuidor: {
                        props: {},
                        titulo: "Distribuidor",
                        componente: FormDistribuidor,
                    },
                    form_movimiento: {
                        props: { usuario: usuario },
                        titulo: 'Movimiento',
                        componente: FormMovimiento,
                    },
                },
            },
            "Comisiones": {
                opcion: {
                    texto: "Porcentaje de comisiones",
                    icono: "fa-hand-holding-dollar",
                },
                pantallas: {
                    comisiones_inscripcion: {
                        props: {},
                        tab: 'Inscripción',
                        titulo: "Comisiones inscripciones",
                        componente: PorcentajeComisionInscripcion,
                    },
                    comisiones_generales: {
                        props: {},
                        tab: 'Miníma',
                        titulo: "Comisiones minímas generales",
                        componente: PorcentajesComisionGeneral,
                    },
                    comisiones_generales_max: {
                        props: {},
                        tab: 'Máxima',
                        titulo: "Comisiones máximas generales",
                        componente: PorcentajeComisionGeneralMax,
                    },
                    formComision: {
                        props: {},
                        titulo: "Comisión",
                        componente: FormPorcentajeComision,
                    }
                },
            },
        }
    }
    else if(obtenerRol(usuario) === 'distribuidor') {
        if(!usuario?.telefono || !usuario?.nombre) {
            let { Movimientos, Productos, ...componentesEditado } = componentes
            componentesEditado.Perfil.pantallas.miperfil.props.completarPerfil = true
            componentes = componentesEditado
        }
        // else dejarlo continuar
    }
    // else rol no identificado

    componentes = {
        ...componentes,
        ...componentesUsuario
    }

    if(cargandoCatalogos) return
    else {
        return (
            <Theme
                lightColor={config.colorPrincipal}
                darkColor={config.colorSecundario}
                compareDark={config.colorPrincipal}
            >
                <Navegador
                    themeDefault="dark"
                    empresa={{
                        nombre: "Camvi432",
                        imagen: "/img/logocamvi.jpg",
                        version: "V 0.0.2408171 demo",
                    }}
                    usuario={{
                        nombre: usuario?.nombre?.toLowerCase(),
                        rol: obtenerRol(usuario),
                        imagen: obtenerFoto(usuario),
                        pantalla: "Perfil"
                    }}
                    componentes={componentes}
                ></ Navegador>
            </Theme>
        )
    }
}

export default Index