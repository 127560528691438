import { createSlice } from "@reduxjs/toolkit"

export const comisionSlice = createSlice({
    name: 'comisionSlice',
    initialState: {
        comisiones: [],
        fechaInicial: null,
        fechaFinal: null,
        tipo: null,
    },
    reducers: {
        agregarComision: (state, actions) => {
            state.comisiones.push(actions.payload)
        },
        fijaComisiones: (state, actions) => {
            state.comisiones = actions.payload
        },
        fijaFechaInicial: (state, actions) => {
            state.fechaInicial = actions.payload
        },
        fijaFechaFinal: (state, actions) => {
            state.fechaFinal = actions.payload
        },
        fijaTipo: (state, actions) => {
            state.tipo = actions.payload
        },
        reiniciaComisiones: (state, actions) => {
            state.comisiones = []
        },
    }
})

export const { 
    agregarComision, 
    fijaComisiones, 
    reiniciaComisiones,
    fijaFechaInicial,
    fijaFechaFinal,
    fijaTipo,
} = comisionSlice.actions
export default comisionSlice.reducer