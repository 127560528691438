import { usuario as infoUsuario, crearCookie } from 'account-react-eurekasigma'
import { Formulario, Input, Select } from 'eureka-design'
import { useCrearInformacionFiscalMutation } from '../../js/services/factura'
import { useState } from 'react'
import { obtenerValor } from '../../js/factura'
import { config } from '../../config/config'

const InformacionFiscal = ({ regimenesSociales }) => {
    const usuario = infoUsuario()
    const informacion_fiscal = usuario?.eureka_pa_cuenta_fiscal

    const [crearInformacionFiscal, {isLoading, isError, isSuccess}] = useCrearInformacionFiscalMutation()

    const [informacionFiscalId, fijaInfoFiscalId] = useState(informacion_fiscal?.id)

    const handlerInformacionFiscal = async (nom, val) => {
        return await crearInformacionFiscal({
            id: informacion_fiscal?.id,
            body: {
                usuario: usuario.id,
                [nom]: val,
            }
        })
        .then((res) => {
            if(res?.data?.mensaje?.id) {
                fijaInfoFiscalId(res.data.mensaje.id)
                handlerProps(nom, val, res.data.mensaje.id)
                return res.data.mensaje.id
            }
            else return informacion_fiscal?.id
        })
    }

    const handlerInput = (val, nom) => {
        val = val?.id ? val.id : val

        handlerInformacionFiscal(nom, val)
        if(informacionFiscalId) handlerProps(nom, val)
    }

    const handlerProps = (nom, val, id = null) => {
        let usuarioEditado = { 
            ...usuario,
            eureka_pa_cuenta_fiscal: {
                ...informacion_fiscal,
                id: id ? id : informacionFiscalId,
                [nom]: val
            }
        }
        crearCookie(config.nomCookieUsuario, JSON.stringify(usuarioEditado), true, config.expCookie)
    }

    return (
        <Formulario
            titulo="Editar Información Fiscal"
            isLoading={[isLoading]}
            isError={[isError]}
            isSuccess={[isSuccess]}
        >
            <Input 
                type="text"
                nombre="rfc"
                iconLeft="fa-id-card"
                placeholder="RFC"
                value={informacion_fiscal?.rfc}
                isLoading={isLoading}
                changeFunction={handlerInput}
            />
            <Input 
                type="text"
                nombre="razonsocial"
                iconLeft="fa-user"
                placeholder="Nombre o Razón Social"
                value={informacion_fiscal?.razonsocial}
                isLoading={isLoading}
                changeFunction={handlerInput}
            />
            <Select 
                iconLeft="fa-user-tie"
                options={regimenesSociales}
                value={obtenerValor(regimenesSociales, informacion_fiscal?.regimen, 'Seleccione un régimen social...')}
                nombre="regimen"
                isLoading={isLoading}
                changeFunction={handlerInput}
                busqueda={{ placeholder: 'Régimen Social' }}
            />
            <Input 
                type="text"
                nombre="codigopostal"
                iconLeft="fa-hashtag"
                placeholder="Codigo Postal"
                value={informacion_fiscal?.codigopostal}
                isLoading={isLoading}
                changeFunction={handlerInput}
            />
            <Input 
                type="text"
                nombre="direccionlinea1"
                iconLeft="fa-house"
                placeholder="Dirección"
                value={informacion_fiscal?.direccionlinea1}
                isLoading={isLoading}
                changeFunction={handlerInput}
                max={100}
            />
        </Formulario>
    )
}

export default InformacionFiscal