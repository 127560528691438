import { useDispatch, useSelector } from "react-redux"
import { Formulario, Input, Select, ImageInput } from "eureka-design"
import { 
    useActualizarFotoMutation,
    useCrearApadrinamientoMutation,
    useCrearDistribuidorMutation, 
    useCrearRolDistribuidorMutation, 
    useObtenerDistribuidoresQuery, 
    useObtenerPadrinoMutation, 
    useObtenerRolDistribuidorQuery, 
    useRelacionarFotoMutation
} from "../../js/services/distribuidor"
import { useState } from "react"
import { setHandler } from "../../js/feactures/HandlerQuerySlice"
import { useCrearTarjetaMutation } from "../../js/services/tarjeta"

export const NOM_FORM_DISTRIBUIDOR = 'formDistribuidor'
const NOM_ABUELO = 'abuelo'
const NOM_PADRE = 'padre'

const FormDistribuidor = () => {
    /** STATES */
    const dispatch = useDispatch()
    const formDistribuidor = useSelector(state => state.handlerQuerySlice[NOM_FORM_DISTRIBUIDOR])
    const [id, fijaID] = useState(formDistribuidor.id)
    const [fotoID, fijaFotoID] = useState(formDistribuidor.foto)
    const [contactoAbueloID, fijaPadrinoAbuelo] = useState(formDistribuidor.contactoAbueloID)
    const [contactoPadreID, fijaPadrinoPadre] = useState(formDistribuidor.contactoPadreID)
    const [abueloID, fijaAbuelo] = useState(formDistribuidor.abueloID)
    const [padreID, fijaPadre] = useState(formDistribuidor.padreID)
    const [crearRegistro] = useState(formDistribuidor.id === null)
    const [tarjeta, fijaTarjeta] = useState(formDistribuidor.eureka_pa_tarjetas[0] ?? null)

    /** HOOK */
    const { data: distribuidores, isLoading: cargandoDistribuidores } = useObtenerDistribuidoresQuery({ col: null, val: null })
    const { data: rolDistribuidor, isLoading: cargandoRol } = useObtenerRolDistribuidorQuery()
    const [crearRol] = useCrearRolDistribuidorMutation()
    const [
        crearApadrinamiento,
        {
            isLoading: actualizandoPadrino,
            isError: padrinoErroneo,
            isSuccess: padrinoActualizado
        }
    ] = useCrearApadrinamientoMutation()
    const [
        crearDistribuidor, 
        { 
            isLoading: actualizandoEntrada, 
            isError: entradaErronea, 
            isSuccess: entradaActualizada
        }
    ] = useCrearDistribuidorMutation()
    const [
        actualizarFoto, 
        { 
            isLoading: actualizandoFoto,
            isSuccess: fotoActualizada,
            isError: fotoErronea,
        }
    ] = useActualizarFotoMutation()
    const [crearTarjeta, {
        isLoading: cargandoTarjeta,
        isError: tarjetaErronea,
        isSuccess: tarjetaActualizada
    }] = useCrearTarjetaMutation()
    const [relacionarFoto] = useRelacionarFotoMutation()
    const [obtenerPadrino] = useObtenerPadrinoMutation()

    async function handlerTarjeta(nom, val) {
        let distribuidorID = null
        if(id === null || id === undefined)
            distribuidorID = await handlerDistribuidor('nombre', '')
        else
            distribuidorID = id

        return await crearTarjeta({
                id: tarjeta?.id ?? null,
                body: { [nom]: val, usuario: distribuidorID }
            })
            .unwrap()
            .then((response) => {
                if(response?.mensaje?.id) {
                    fijaTarjeta(response.mensaje)
                    handlerFormData('eureka_pa_tarjetas', [response.mensaje])
                }
                // else ya se creo el registro
            })
    }

    async function handlerDistribuidor(nom, val) {
        return await crearDistribuidor({
                id: id,
                body: { [nom]: val, estatus: 'activo' }
            })
            .unwrap()
            .then((response) => {
                if (response?.mensaje?.id) {
                    let distribuidorID = response.mensaje.id
                    crearRol({
                        rolID: rolDistribuidor[0].id,
                        usuarioID: distribuidorID,
                    })
                    fijaID(distribuidorID)
                    handlerFormData('id', distribuidorID)
                    return distribuidorID
                }
                // else se actualizo el registro
            })
    }

    async function handlerApadrinamiento(apadrinamientoID = null, relacion = '', padrino = null) {
        let distribuidorID = null
        if(id === null || id === undefined)
            distribuidorID = await handlerDistribuidor('nombre', '')
        else
            distribuidorID = id

        return await crearApadrinamiento({
            id: apadrinamientoID,
            relacion: relacion,
            usuario: distribuidorID,
            contacto: padrino,
        })
        .unwrap()
        .then((response) => {
            if (response?.mensaje?.id) {
                let apadrinamientoID = response.mensaje.id
                if(relacion === NOM_ABUELO) {
                    fijaPadrinoAbuelo(apadrinamientoID)
                    handlerFormData('contactoAbueloID', apadrinamientoID)
                }
                else if(relacion === NOM_PADRE) {
                    fijaPadrinoPadre(apadrinamientoID)
                    handlerFormData('contactoPadreID', apadrinamientoID)
                }
                // else relacion desconocida
            }
            // else se actualizo el registro
        })
    }

    async function handlerFoto(e) {
        let distribuidorID = null
        if(id === null || id === undefined)
            distribuidorID = await handlerDistribuidor('nombre', '')
        else
            distribuidorID = id

        return await actualizarFoto({
            id: fotoID,
            file: e.target.files[0],
            carpeta: 'camvi432_usuario',
            nombre: 'nombre',
        })
        .unwrap()
        .then((response) => {
            if (response?.mensaje?.id) {
                fijaFotoID(response.mensaje.id)
                handlerFormData('foto', response.mensaje.id)
                relacionarFoto({ id: response.mensaje.id, usuario: distribuidorID })
            }
            // else se actualizo el registro
            handlerFormData('preview', e.target.files[0])
        })
    }

    const handlerInputTarjeta = (val, nom) => {
        let tarjetaEditada = {
            ...tarjeta,
            [nom]: val
        }
        handlerFormData('eureka_pa_tarjetas', [tarjetaEditada])
        handlerTarjeta(nom, val)
    }

    const handlerSelect = (e, nom) => {
        let padrinoID = e.value
        obtenerPadrino({
            id: padrinoID,
        })
        .unwrap()
        .then((response) => {
            let padrinosAbuelos = response.eureka_bs_contactos.filter(contacto => contacto.relacion === NOM_PADRE)
            let padrinoAbueloID = (padrinosAbuelos.length > 0) ? padrinosAbuelos[0].contacto : null

            fijaPadre(padrinoID)
            handlerApadrinamiento(contactoPadreID, nom, padrinoID)
            fijaAbuelo(padrinoAbueloID)
            handlerApadrinamiento(contactoAbueloID, NOM_ABUELO, padrinoAbueloID)
        })
    }

    const handlerInput = (val, nom) => {
        handlerFormData(nom, val)
        handlerDistribuidor(nom, val)
    }

    const handlerFormData = (key, val) => {
        dispatch(setHandler({ 
            state: NOM_FORM_DISTRIBUIDOR, 
            content: { 
                id: id, 
                abuelo: abueloID, 
                padre: padreID,
                contactoAbuelo: contactoAbueloID,
                contactoPadre: contactoPadreID,
                foto: fotoID,
                [key]: val
            } 
        }))
    }

    const adaptarDatosSelector = (data = [], nom) => {
        data = data.filter((e) => {
            if(nom === NOM_PADRE) {
                return e.id !== id && e.id !== abueloID
            }
            else {
                return false
            }
        })
        
        return data.map((e) => {
            return { text: e.nombre, value: e.id }
        })
    }

    if(cargandoDistribuidores || cargandoRol) {
        return
        // return <p>Cargando, por favor espere...</p>
    }
    else {
        return (
            <Formulario 
                titulo={crearRegistro ? 'Crear Distribuidor' : 'Editar Distribuidor'}
                isLoading={[actualizandoEntrada, actualizandoPadrino, actualizandoFoto, cargandoTarjeta]}
                isError={[entradaErronea, padrinoErroneo, fotoErronea, tarjetaErronea]}
                isSuccess={[entradaActualizada, padrinoActualizado, fotoActualizada, tarjetaActualizada]}
            >
                <ImageInput 
                    id={'fotoPerfil'}
                    preview={formDistribuidor.preview}
                    avatar={true}
                    changeFunction={handlerFoto}
                    isLoading={actualizandoFoto}
                    width={200}
                    height={200}
                />
                <Input 
                    nombre="nombre"
                    placeholder="Nombre"
                    value={formDistribuidor.nombre}
                    changeFunction={handlerInput}
                    isLoading={actualizandoEntrada}
                    min={5}
                    max={50} 
                />
                <Input 
                    nombre="correo"
                    placeholder="Correo"
                    value={formDistribuidor.correo}
                    changeFunction={handlerInput}
                    isLoading={actualizandoEntrada}
                    min={10}
                    max={50} 
                />
                <Input 
                    nombre="telefono"
                    placeholder="Teléfono"
                    value={formDistribuidor.telefono}
                    changeFunction={handlerInput}
                    isLoading={actualizandoEntrada}
                    min={10}
                    max={20}
                />
                <Select
                    nombre={NOM_PADRE}
                    value={formDistribuidor.padreID ?? 'Seleccione un padre'}
                    options={adaptarDatosSelector(distribuidores, NOM_PADRE)}
                    isLoading={actualizandoPadrino}
                    changeFunction={handlerSelect}
                    busqueda={{
                        placeholder: 'Seleccione un padre'
                    }}
                />
                <Input 
                    nombre="numero"
                    placeholder="Número de cuenta"
                    value={formDistribuidor.eureka_pa_tarjetas[0]?.numero}
                    isLoading={cargandoTarjeta}
                    changeFunction={handlerInputTarjeta}
                />
                <Input 
                    nombre="cliente_stripe"
                    placeholder="Banco"
                    value={formDistribuidor.eureka_pa_tarjetas[0]?.cliente_stripe}
                    isLoading={cargandoTarjeta}
                    changeFunction={handlerInputTarjeta}
                />
            </Formulario>
        )
    }
}

export default FormDistribuidor