import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { config } from "../../config/config"
import { EkQuery } from "../query"
import { token } from "account-react-eurekasigma"

const TABLA_TARJETAS = 'EurekaPaTarjeta'

export const tarjetaApi = createApi({
    reducerPath: 'tarjetaApi',
    baseQuery: fetchBaseQuery({ baseUrl: config.crufdekURL }),
    tagTypes: ['Tarjetas'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        crearTarjeta: builder.mutation({
            query: (data) => {
                let body = null
                if (data.id !== null) {
                    body = new EkQuery(TABLA_TARJETAS)
                                .where("id", data.id)
                                .update(data.body)
                }
                else {
                    body = new EkQuery(TABLA_TARJETAS)
                                .create(data.body)
                }

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
    })
})

export const {
    useCrearTarjetaMutation
} = tarjetaApi