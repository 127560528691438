import { Formulario, Input, Tabla, buildData, ModalForm, Select } from "eureka-design"
import { useCrearConceptoMutation, useCrearMovimientoMutation, useObtenerMovimientoQuery, useObtenerNumerosReferenciasQuery } from "../../js/services/movimiento"
import { useCrearProductoPrecioMutation, useObtenerProductosComprasQuery } from "../../js/services/producto"
import { useObtenerDistribuidorQuery, useObtenerDistribuidoresQuery } from "../../js/services/distribuidor"
import ResumenVenta from "../../components/ventas/ResumenVenta"
import { numberFormat, obtenerRol } from "../../js/util"
import { useEffect, useState } from "react"
import { AdminComision } from "../../js/AdministradorComision"
import { token } from "account-react-eurekasigma"
import "../../css/util.css"

export const NOM_FORM_MOVIMIENTO = 'formMovimiento'
const REGISTROS_POR_PAGINA = 15
const inputs = [
    {nom: 'token_stripe', placeholder: 'Referencia de pago', type: 'text'}
]
const AFILIADOS = 'offline'
const COMPRAS = 'online'
// const TODOS = null
const TIPO_AFILIADO = 'afiliación'
const TIPO_COMPRA = 'compra'

export default function FormMovimiento({ UpProps, ShowAlert, usuario, formMovimiento }) {
    /** ESTADOS GLOBALES*/
    const esAdministrador = obtenerRol(usuario) === 'administrador'
   
    /** ESTADOS */
    const [ventaFinalizada, cambiarEstadoVenta] = useState(false)

    const [mostrarModalProductos, fijaEstadoModalProductos] = useState(false)
    const [seGuardoTipo, cambiarEstadoGuardarTipo] = useState(formMovimiento?.tipo ? true : false)

    const [movimientoID, fijaMovimientoID] = useState(formMovimiento.id)
    const [vendedorID, fijaVendedorID] = useState(formMovimiento.vendedor)
    const [tipoProductos, fijaTipoProductos] = useState(null)
    const [tipoVenta, fijaTipoVenta] = useState(formMovimiento.tipo ? formMovimiento.tipo : '')
    const [fecha, fijaFecha] = useState(formMovimiento?.fecha)

    const [busqueda, fijaBusqueda] = useState({ col: null, val: null })
    const [iniciarBusquedad, fijaIniciarBuscqueda] = useState(false)

    /** HOOK */
    const { data:movimiento, isLoading:cargandoMovimiento, refetch:actualizarMovimiento } = useObtenerMovimientoQuery({ id: movimientoID })
    const { 
        data: productos, 
        isLoading: cargandoProductos, 
        refetch: actualizarProductos 
    } = useObtenerProductosComprasQuery({ mostrarArchivados: false, tipoProducto: tipoProductos, ...busqueda })
    const { 
        data: distribuidores, 
        isLoading: cargandoDistribuidores 
    } = useObtenerDistribuidoresQuery({ col: null, val: null })
    const {
        data: vendedor,
        isLoading: cargandoVendedor,
    } = useObtenerDistribuidorQuery({ token: token(), col: 'id', val: formMovimiento?.vendedor })
    const [
        crearMovimiento, 
        { 
            isLoading: actualizandoEntrada, 
            isError: entradaErronea, 
            isSuccess: entradaActualizada
        }
    ] = useCrearMovimientoMutation()
    const [crearConcepto] = useCrearConceptoMutation()
    const [actualizarPrecio] = useCrearProductoPrecioMutation()
    const {
        data: numsReferencias, 
        isLoading: cargandoNumsReferencias, 
    } = useObtenerNumerosReferenciasQuery()

    useEffect(() => {
        if(vendedorID && vendedor) {
            if(AdminComision.tengoVentaRealizada(vendedor.eureka_st_transacciones)) { 
                fijaTipoProductos(COMPRAS)
                fijaTipoVenta(TIPO_COMPRA)
            }
            else {
                fijaTipoProductos(AFILIADOS)
                fijaTipoVenta(TIPO_AFILIADO)
            }
        }
        // else no hay vendedor seleccionado para la venta aún
    }, [vendedorID, vendedor, movimientoID, fijaTipoProductos, fijaTipoVenta])

    async function handlerMovimiento(nom, val, movimientoCreadoID = null) {
        return await crearMovimiento({
                id: movimientoID ? movimientoID : movimientoCreadoID,
                body: { 
                    vendedor: vendedorID, 
                    tipo: tipoVenta ? tipoVenta : '',
                    [nom]: val 
                }
            })
            .unwrap()
            .then(async function(response) {
                if (response?.mensaje?.id) {
                    let id = response.mensaje.id
                    fijaMovimientoID(id)
                    handlerFormData('id', id)
                    handlerFormData('total', 0)
                    return id
                }
                // else se actualizo el registro producto
                await actualizarMovimiento()
                return movimientoID
            })
    }

    async function handlerConcepto(conceptoID = null, body = {}, totalConcepto = {}, eliminarConcepto = false) {
        let id = null
        if(eliminarConcepto) {
            body['transaccion'] = null
        }
        else if(movimientoID === null) {
            id = await handlerMovimiento('moneda', 'MXN')
            body['transaccion'] = id
        }
        else {
            id = movimientoID
            body['transaccion'] = movimientoID
        }

        return await crearConcepto({
            id: conceptoID,
            body: body
        })
        .unwrap()
        .then(() => { 
            actualizarMovimiento()
            return true
        })
    }

    const handlerFecha = async () => {
        if(!fecha) {
            let fechaCreacion = new Date()
            fechaCreacion = `${fechaCreacion.getFullYear()}-${fechaCreacion.getMonth()+1}-${fechaCreacion.getDate()} ${fechaCreacion.getHours()}:${fechaCreacion.getMinutes()}:${fechaCreacion.getSeconds()}`

            fijaFecha(fechaCreacion)
            return {
                id: await handlerMovimiento('fecha', fechaCreacion),
                fecha: fechaCreacion,
            }
        }
        else {
            return {
                id: movimientoID,
                fecha: fecha
            }
        }
    }

    const handlerInput = async (val, nom) => {
        let id = null
        let data = null

        if(nom === 'token_stripe' && numsReferencias.includes(val)) {
            ShowAlert({
                icono: 'error',
                titulo: 'Referencia inválida',
                mensaje:`El número de refencia: ${val} ya esta ligado a un registro, por favor ingrese otro`,
                time: 4
            })
        }
        else if(nom === 'fecha') {
            val = `${val.getFullYear()}-${val.getMonth()+1}-${val.getDate()} ${val.getHours()}:${val.getMinutes()}:${val.getSeconds()}`
        }
        else {
            data = await handlerFecha()
            id = data?.id
        }
        handlerFormData(nom, val, {
            [nom]: val,
            fecha: data?.fecha,
        }, id)
        handlerMovimiento(nom, val, id)
    }

    async function handlerSelect(e, nom) {
        let data = null
        let id = null
        let valor = parseInt(e.value)

        data = await handlerFecha()
        id = data.id

        fijaVendedorID(valor)
        handlerFormData(nom, valor, {
            [nom]: valor,
            'nomVendedor': e.text,
            fecha: data?.fecha
        }, id)
        handlerMovimiento(nom, valor, id)
    }

    const handlerFormData = (key, val, data = {}, id = null) => {
        id = id ? id : movimientoID

        UpProps({
            [NOM_FORM_MOVIMIENTO]: {
                ...formMovimiento,
                id: id,
                [key]: val,
                ...data,
            }
        })
    }

    const adaptarDatosSelector = (data = []) => {
        data = data.filter(e => e.id !== formMovimiento.vendedor)       
        return data.map((e) => {
            return { text: e.nombre, value: e.id }
        })
    }

    const terminarMovimiento = () => {
        let liquidado
        if(movimientoID !== null) {
            liquidado = formMovimiento.cxp_cxc === 'liquidado'

            if(liquidado) {
                cambiarEstadoVenta(false)
                handlerFormData('cxp_cxc', 'cobrar')
                handlerMovimiento('cxp_cxc', 'cobrar')
            }
            else {
                cambiarEstadoVenta(true)
                handlerFormData('cxp_cxc', 'liquidado')
                handlerMovimiento('cxp_cxc', 'liquidado')
            }

            movimiento?.eureka_st_transacciones_conceptos?.map((c) => {
                let cantidad = parseInt(c.cantidad)
                let inventario = c.eureka_st_producto?.eureka_st_productos_precios[0]?.numero
                let precioID = c.eureka_st_producto?.eureka_st_productos_precios[0]?.id

                actualizarPrecio({
                    id: precioID,
                    body: { numero: liquidado ? (inventario + cantidad) : (inventario - cantidad) }
                })
                .unwrap()
                .then(() => {
                    actualizarMovimiento()
                    actualizarProductos()
                })
                return true
            })
        }
        // else no existe un registro para el movimiento a terminar
    }

    if(cargandoProductos || cargandoDistribuidores || cargandoMovimiento || cargandoVendedor || cargandoNumsReferencias) {
        return
        // return <p>Cargando, por favor espere...</p>
        // return (
        //     <CargandoPagina
        //         colorPrincipal={config.colorPrincipal}
        //         mensaje="Cargando pantalla, por favor espere..." 
        //     />
        // )
    }
    else {
        let botonesTabla = []
        let botonesHead = []

        if(esAdministrador) {
            botonesTabla.push({
                texto: formMovimiento.cxp_cxc === 'liquidado' ? 'Pasar como adeudo' : 'Terminar movimiento',
                onClick: terminarMovimiento
            })
        }
        //else no puedes terminar la venta

        if(!ventaFinalizada) {
            botonesHead.push({
                icono: 'fa-solid fa-plus',
                tooltip: 'Agregar producto',
                onClick: () => { fijaEstadoModalProductos(true) }
            })
        }
        // else la venta a finalizado

        if(tipoVenta && movimientoID && esAdministrador && !seGuardoTipo) {
            cambiarEstadoGuardarTipo(true)
            handlerMovimiento('tipo', tipoVenta, movimientoID)
        }
        // else no guardar automaticamente el campo tipo de venta

        const actualizarInventario = (cantSeleccionada = 1, producto = {}, agregar = true, mostrarMensaje = false) => {
            let detallesProducto = producto?.eureka_st_productos_precios
            let detalleProducto = detallesProducto?.length > 0 ? detallesProducto[0] : null
            let inventario = parseInt(detalleProducto.numero)

            cantSeleccionada = agregar ? cantSeleccionada : (-1 * cantSeleccionada)

            if(agregar || cantSeleccionada <= inventario) {
                if(formMovimiento.cxp_cxc === 'liquidado') {
                    actualizarPrecio({
                        productoID: producto?.id,
                        body: { numero: inventario + cantSeleccionada }
                    })
                    .unwrap()
                    .then(() => {
                        actualizarProductos()
                        actualizarMovimiento()
                    })
                }
                // else no es necesario regresar el inventario
            } 
            else {
                if(mostrarMensaje) {
                    ShowAlert({
                        icono: 'info',
                        titulo: 'Sin Existencia',
                        mensaje: 'El producto no cuenta con suficiente existencia.',
                        time: 4
                    })
                }
                // else no es necesario mostrar el mensaje
            }
        }

        return (
            <>
                <ModalForm show={mostrarModalProductos} onClose={() => { fijaEstadoModalProductos(false) }}>
                    <Tabla
                        checkbox={false}
                        busqueda={{
                            placeholder: "Buscar...",
                            fn: (val) => {
                                if(iniciarBusquedad) {
                                    fijaBusqueda({
                                        col: 'nombre',
                                        val
                                    })
                                    actualizarProductos()
                                }
                                else {
                                    fijaIniciarBuscqueda(true)
                                }
                            }
                        }}
                        paginado={{
                            paginas: Math.round(productos.length / REGISTROS_POR_PAGINA),
                            paginaCurrent: 1,
                            registrosCurrent: productos.length < REGISTROS_POR_PAGINA ? productos.length : REGISTROS_POR_PAGINA,  
                            registros: productos.length,
                            onNext: () => {},
                            onPrev: () => {},
                        }}
                        headers={[
                            { text: "Nombre",     key: "nombre",   type: "text" },
                            { text: "Costo",      key: "costo",    type: "precio" },
                            { text: "Precio",     key: "precio",   type: "number" },
                            { text: "Inventario", key: "cantidad", type: "text" },
                        ]}
                        data={buildData(productos, [
                            { key: 'nombre', type: 'text' },
                            { key: 'costo',  type: 'precio' },
                            { 
                                key: "precio", 
                                type: "number", 
                                text: (item) => {
                                    let precio = item?.eureka_st_productos_precios[0]?.precio
                                    return precio ? `$${Number(precio).toFixed(2)}` : 'Sin precio'
                                },
                                value: (item) => {
                                    let precio = item?.eureka_st_productos_precios[0]?.precio
                                    return precio ? precio : 0
                                }
                            },
                            { 
                                key: "cantidad", 
                                type: "text", 
                                text: (item) => {
                                    let inventario = item?.eureka_st_productos_precios[0]?.numero
                                    return inventario ? inventario : '0'
                                },
                                value: (item) => {
                                    let inventario = item?.eureka_st_productos_precios[0]?.numero
                                    return inventario ? inventario : 0
                                }
                            }
                        ])}
                        onClickRow={(item) => {
                            let producto = item.item
                            let precio = producto?.eureka_st_productos_precios?.length > 0 ? producto.eureka_st_productos_precios[0] : null

                            let conceptosFiltrados = movimiento?.eureka_st_transacciones_conceptos?.filter(c => c.producto === item.id)
                            let inventario = precio.numero ?? 0
                            let body = null
                            let id = null
                            let totalAnt = 0
                            let totalNuevo = 0

                            if(inventario > 0) {
                                if(conceptosFiltrados?.length > 0) {
                                    let concepto = conceptosFiltrados[0]
                                    id = concepto.id
                                    body = { cantidad: parseInt(concepto.cantidad) + 1}
                                    totalAnt += parseFloat(concepto.costo) *  parseFloat(concepto.cantidad)
                                    totalNuevo += parseFloat(concepto.costo) *  (parseFloat(concepto.cantidad) + 1)
                                }
                                else {
                                    body = {
                                        nombre: producto.nombre,
                                        costo: parseFloat(precio.precio),
                                        cantidad: 1,
                                        producto: item.id,
                                    }
                                }
                            }
                            else {
                                ShowAlert({
                                    icono: 'info',
                                    titulo: 'Sin Existencia',
                                    mensaje: 'El producto no cuenta con suficiente existencia.',
                                    time: 4
                                })
                            }

                            if(body !== null) {
                                handlerConcepto(id, body, { ant: totalAnt, nuevo: totalNuevo })
                                actualizarInventario(1, producto, false, false)
                                fijaEstadoModalProductos(false)
                            }
                            // else el producto no se puede agregar
                        }}
                    >
                    </Tabla>
                </ModalForm>
                
                <Formulario 
                    titulo="Crear un Movimiento"
                    isLoading={[actualizandoEntrada]}
                    isError={[entradaErronea]}
                    isSuccess={[entradaActualizada]}
                    botones={botonesTabla}
                >
                    <Select
                        nombre="vendedor"
                        value={formMovimiento?.nomVendedor ?? 'Seleccione un distribuidor'}
                        options={adaptarDatosSelector(distribuidores)}
                        isLoading={actualizandoEntrada}
                        changeFunction={handlerSelect}
                        busqueda={{
                            placeholder: 'Seleccione un distribuidor'
                        }}
                        disabled={vendedorID ? true : false}
                    />
                    {
                        inputs.map((input, index) => {
                            return <Input
                                        key={index}
                                        type={input.type}
                                        changeFunction={handlerInput}
                                        value={formMovimiento[input.nom]}
                                        isLoading={actualizandoEntrada}
                                        nombre={input.nom}
                                        placeholder={input.placeholder}
                                    />
                        })
                    }
                    <Input
                        type="date"
                        changeFunction={handlerInput}
                        value={fecha}
                        isLoading={actualizandoEntrada}
                        nombre="fecha"
                        placeholder="Fecha"
                        dateConfig={{
                            maxYear: 2050,
                            minYear: 2023,
                            timePicker: true,
                            formato: '{S} {DD} de {MM} del {YYYY}',
                        }}
                        disabled={esAdministrador ? false : true}
                    />

                    <Tabla
                        checkbox={false}
                        noData="No hay productos agregados"
                        botonesHead={ventaFinalizada ? [] : botonesHead}
                        headers={[
                            { text: "Nombre",   key: "nombre",   type: "text" },
                            { text: "Costo",    key: "costo",    type: "precio" },
                            { text: "Precio",   key: "precio",   type: "precio" },
                            { text: "Cantidad", key: "cantidad", type: "text" },
                            { text: "Total",    key: "total",    type: "number" },
                        ]}
                        data={buildData(
                            movimiento?.eureka_st_transacciones_conceptos ?? [], 
                            [
                                { key: 'nombre',  type: 'text' },
                                { 
                                    key: "costo",
                                    type: "precio",
                                    text: (item) => {
                                        return numberFormat(item?.eureka_st_producto?.costo, 2, '.', ',', '$')
                                    }
                                },
                                { key: 'costo',   type: 'precio' },
                                {

                                    key: 'catidad', 
                                    type: 'text', 
                                    input: {
                                        type: 'text',
                                        nombre: 'cantidad',
                                        onEnter: async function(value, nombre, row) {
                                            let concepto = row.item
                                            let producto = concepto.eureka_st_producto
                                            let cantSeleccionada = parseInt(value)

                                            let cantidad = parseInt(concepto?.cantidad)
                                            let inventario = parseInt(producto?.eureka_st_productos_precios[0]?.numero)
                                            let inventarioOriginal = cantidad + inventario

                                            if(cantSeleccionada > 0 && cantSeleccionada <= inventarioOriginal) {
                                                let costo = concepto?.costo
                                                let body = { cantidad: cantSeleccionada }

                                                await handlerConcepto(concepto.id, body, {
                                                    ant: costo * cantidad,
                                                    nuevo: costo * cantSeleccionada,
                                                })
                                                cantidad = -1 * (cantSeleccionada - cantidad) 
                                                actualizarInventario(cantidad, producto, cantidad >= 0, false)
                                            }
                                            else {
                                                ShowAlert({
                                                    icono: 'info',
                                                    titulo: 'Cantidad Erronea',
                                                    mensaje: 'La cantidad seleccionada es incorrecta o ha superado el inventario del producto.',
                                                    time: 4
                                                })
                                            }
                                        }
                                    },
                                    text: (item) => item.cantidad,
                                    value: (item) => item.cantidad
                                },
                                { 
                                    key: 'total',
                                    type: 'number', 
                                    text: (item) => {
                                        return (
                                            <>
                                                <span>${Number(item.costo * item.cantidad).toFixed(2)}</span>
                                                <input id={`productoConcepto${item.id}`} type="hidden" value={item.producto} />
                                                <input id={`productoInv${item.id}`} type="hidden" value={item.eureka_st_producto.eureka_st_productos_precios[0].numero} />
                                            </>
                                        )
                                    }
                                },
                            ]
                        )}
                        botonesRow={[
                            {
                                icono: 'fa-solid fa-trash-can',
                                tooltip: 'Quitar producto',
                                onClick: (item) => {
                                    let concepto = item.item
                                    let producto = concepto.eureka_st_producto
                                    let precio = parseFloat(concepto.costo)
                                    let cantidad = parseInt(concepto.cantidad)
                                    let inventario = producto?.eureka_st_productos_precios[0]?.numero
                                    let body = { transaccion: null }

                                    handlerConcepto(concepto.id, body, { ant: precio * cantidad, nuevo: 0 }, true)
                                    if(formMovimiento.cxp_cxc === 'liquidado') {
                                        actualizarPrecio({
                                            productoID: producto.id,
                                            body: { numero: inventario + cantidad }
                                        })
                                        .unwrap()
                                        .then(() => {
                                            actualizarMovimiento()
                                            actualizarProductos()
                                        })
                                    }
                                    // else no hace falta regresar la cantidad al inventario
                                }
                            }
                        ]}
                    >
                    </Tabla>
                    
                    <ResumenVenta 
                        productos={movimiento?.eureka_st_transacciones_conceptos ?? []}
                        total={formMovimiento.total}
                    />
                </Formulario>
            </>
        )
    }
}