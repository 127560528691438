import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { config } from "../../config/config"
import { EkQuery } from "../query"
import { token } from "account-react-eurekasigma"

const TABLA_INFO_FISCAL = 'EurekaPaCuentaFiscal'

export const facturaApi = createApi({
    reducerPath: 'facturaApi',
    baseQuery: fetchBaseQuery({ baseUrl: config.crufdekURLV1 }),
    tagTypes: ['Facturas'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        crearInformacionFiscal: builder.mutation({
            /**
             * @param {{ id: number, body: object }} data 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_INFO_FISCAL)

                if(data?.id) {
                    body = body.where('id', data.id).update(data.body)
                }
                else {
                    body = body.create(data.body)
                }

                return {
                    url: `/CUFD?key=${config.keyAPICrufdek}&type=API`,
                    method: "POST",
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
    })
})

export const {
    useCrearInformacionFiscalMutation
} = facturaApi