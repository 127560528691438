import { MiPerfil } from "eureka-design"
import { obtenerFoto, obtenerRol, ocultarNumCuenta } from "../../js/util"
import { usuario as infoUsuario } from "account-react-eurekasigma"
import { useEffect } from "react"
import { obtenerValor } from "../../js/factura"

export default function Perfil({ Push, completarPerfil, regimenesSociales }) {
    const usuario = infoUsuario()
    const informacion_fiscal = usuario?.eureka_pa_cuenta_fiscal

    useEffect(() => {
        if(completarPerfil) {
            if(!usuario?.telefono || !usuario?.nombre) {
                Push('editarperfil', {
                    mensaje: 'Por favor, completa tu información, proporcionando tu nombre y número de teléfono.'
                })
            }
            else window.location.reload()
        }
        // else continuar sin validar
    })

    return (
        <>
            <MiPerfil
                avatar={obtenerFoto(usuario)}
                nombre={usuario.nombre ?? 'Sin nombre'}
                correo={usuario.correo}
                telefono={usuario.telefono ?? 'Sin número telefonico'}
                extrasInfo={[
                    { titulo: 'Rol', contenido: obtenerRol(usuario) },
                    { titulo: 'Numero de cuenta', contenido: ocultarNumCuenta(usuario?.eureka_pa_tarjetas?.length > 0 ? usuario?.eureka_pa_tarjetas[0]?.numero : null) },
                    { titulo: 'RFC', contenido: informacion_fiscal?.rfc ?? 'Ninguno' },
                    { titulo: 'Razón social', contenido: informacion_fiscal?.razonsocial ?? 'Ninguna' },
                    { titulo: 'Régimen social', contenido: obtenerValor(regimenesSociales, informacion_fiscal?.regimen, 'Ninguno') },
                    { titulo: 'Código postal', contenido: informacion_fiscal?.codigopostal ?? 'Ninguno' },
                ]}
                botones={[
                    {
                        texto: 'Editar perfil',
                        iconLeft: 'fas fa-user-edit',
                        onClick: () => { Push('editarperfil') }
                    },
                    {
                        texto: 'Editar información fiscal',
                        iconLeft: 'fa-file-invoice',
                        onClick: () => { Push('informacion_fiscal') }
                    },
                    {
                        texto: 'Cerrar sesión',
                        iconLeft: 'fas fa-arrow-right-from-bracket',
                        onClick: () => { window.location.href = '/cerrar_sesion' }
                    }
                ]}
            >
            </MiPerfil>
        </>
    )
}